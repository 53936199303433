export const $ = elm => document.querySelector(elm);
export const $$ = elm => {
	const selector = document.querySelectorAll(elm);
	if (typeof selector == "object") {
		return [].slice.call(document.querySelectorAll(elm));
	} else {
		return [];
	}
};

/**
 * Alternativa a Object.values (indisponível no safari)
 */
export const extractValues = obj => {
	var concat = [];
	for (var i in obj) {
		if (obj.hasOwnProperty(i)) {
			concat.push(obj[i]);
		}
	}
	return concat;
};

/**
 * Incrementa uma string com dados de busca de voo
 * através de um reducer
 * @param {String} urlString string a ser incrementada
 * @param {String} rawAttribute contendo os dados da busca
 */
export const flightParamsToUrl = (urlString, rawAttribute) => {
	var searchParam = extractValues(rawAttribute)[0];
	urlString += "/" + (searchParam && searchParam.iata ? searchParam.iata : searchParam);
	return urlString;
};

/**
 * Cria um objeto no que armazena dados da busca no localstorage
 * através de um reducer
 * @param {Object} localStorageObj
 * @param {String} attribute
 */
export const flightParamsToLocalStorage = (localStorageObj, attribute) => ({
	...localStorageObj,
	[Object.keys(attribute)[0]]: extractValues(attribute)[0]
});

/**
 * converte a data em formato mysql em um inteiro
 * @param {String} strDate data no formato YYYY-MM-DD
 */
export const strDateToInt = strDate => (strDate ? parseInt(strDate.replace(/\-/g, "")) : "");

/**
 * Diz se a janela é menor que o breakpoint de mobile (991px)
 * @returns {Boolean}
 */
export const isMobile = () => {
	var w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName("body")[0],
		x = w.innerWidth || e.clientWidth || g.clientWidth;

	return x < 991;
};

/**
 * Decora um elemento com o tooltip de erro
 * @param {HTMLElement} element elemento html a ser envolvido pela classe e mensagem de erro
 * @param {String} message mensagem de erro a ser exibida
 * @param {String} dataFlow up|down
 */
export const decorateWithError = (element, message = null, dataFlow = "down") => {
	element.className += " has-error";
	element.setAttribute("data-tooltip", message || "Preencha o campo corretamente.");
	element.setAttribute("data-show", "true");
	element.setAttribute("data-flow", dataFlow);
	element.setAttribute("aria-label", message || "Preencha o campo corretamente.");
	element.setAttribute("role", "alert");
	element.setAttribute("aria-invalid", "true");

	return element;
};

/**
 * remove os atributos de tooltip de um elemento html
 * @param {HTMLElement} element elemento a ter atributos de tooltip removidos
 */
export const removeErrorDecoration = element => {
	element.className = element.className.replace(/has-error/g, "");
	element.removeAttribute("data-tooltip");
	element.removeAttribute("data-show");
	element.removeAttribute("data-flow");
	element.removeAttribute("aria-label");
	element.removeAttribute("role");
	element.removeAttribute("aria-invalid");

	return element;
};
