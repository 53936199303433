import {
	autoCompleteCallback,
	nanoAjaxAdapter,
	autoCompleteSelectCallback,
	autoCompleteRenderItem,
	setDataFromRequest,
	setDataFromStorage,
	displayName
} from "./utils/autocompleteDomUtils";
import { autoComplete } from "../../vendor/autocomplete";
import getURLParams from "../../shared/getURLParams";
import { retrieveStretch } from "./utils/localStorageUtils";
import { $ } from "../../shared/utils";
import { trackAutoCompleteInit } from "./utils/searchTrackers";

export default class AutoCompleteWrapper {
	constructor(element, stretch) {
		this.elm = element;
		this.stretch = stretch;
		this.airportData = null;
		this.init();
	}

	init() {
		this.setInputValue(window.location.href);
		const instance = this;

		const resultCallback = (term, hasResultData) => {
			const icon = this.elm.parentNode.querySelector("i");
			
			icon.classList.remove("loader", "loader-fetching", "form-control-feedback");
			icon.classList.add("icon-pin", "form-control-feedback");

			try {
				trackAutoCompleteInit(this.stretch, hasResultData, term);
			} catch(e) {
				console.log("Error tracking autocomplete init on GA");
			}

		};

		new autoComplete(this.elm.id, {
			selector: `#${this.elm.id}`,
			minChars: 3,
			source: autoCompleteCallback.bind(this, nanoAjaxAdapter, "/airports/search/", resultCallback),
			renderItem: autoCompleteRenderItem,
			onSelect: autoCompleteSelectCallback.bind(this, instance),
			notFoundText: "Local não identificado, tente de outra forma."
		});
	}

	/**
	 * Seta o nome da origem/destino no input
	 * @param {HTMLElement} elm input do autocomplete
	 * @param {Object} airportData objeto contendo iata, name e city como atributos.
	 */
	decorateField(elm, airportData, fromLocalStorage, fromGeoLocation) {
		if (elm && airportData && airportData !== null) {
			this.airportData = airportData;
			elm.value = displayName(airportData, fromLocalStorage, fromGeoLocation);
		}
	}

	/**
	 * Insere um valor no input do autocomplete,
	 * * Se vier um parâmetro na url (?to=RIO ou ?from=VIX) deve
	 * ser aplicado conforme stretch em oposição ao valor do
	 * localstorage
	 * * se houver valor no localStorage correspondente á stretch
	 * da classe, deve ser usado.
	 * @param {String} href valor da url a ser avaliada
	 */
	setInputValue(href) {
		const iataFromURL = getURLParams(href)[this.stretch];
		const airportDataFromStorage = retrieveStretch(this.stretch);

		if (iataFromURL && this.airportData == null) {
			const endpoint = `airports/search/${iataFromURL}`;
			const nanoAjaxCallback = setDataFromRequest.bind(null, this, iataFromURL);
			nanoAjaxAdapter(endpoint, null, nanoAjaxCallback);
		} else if (airportDataFromStorage && this.airportData == null) {
			setDataFromStorage(this, airportDataFromStorage);
		}
	}

	updateValue() {
		if (this.airportData) {
			setDataFromStorage(this, this.airportData);
		} else {
			this.elm.value = "";
		}
	}

	setData(airportData) {
		this.airportData = airportData;

		if (airportData && $("#change_flight_direction")) {
			$("#change_flight_direction").classList.remove("disabled");
		}
	}

	getData() {
		return this.airportData;
	}
}
