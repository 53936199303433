export const feriadosData = [
	{
		feriado: "finados",
		min: "2019-11-01",
		max: "2019-11-05"
	},
	{
		feriado: "corpus-christi",
		min: "2019-06-19",
		max: "2019-06-24"
	},
	{
		feriado: "dia-do-trabalho",
		min: "2018-04-27",
		max: "2018-05-02"
	},
	{
		feriado: "dia-das-maes",
		min: "2018-05-11",
		max: "2018-05-14"
	},
	{
		feriado: "dia-dos-namorados",
		min: "2019-06-08",
		max: "2019-06-18"
	},
	{
		feriado: "festa-junina",
		min: "2018-06-01",
		max: "2018-06-30"
	},
	{
		feriado: "independencia-do-brasil",
		min: "2018-09-06",
		max: "2018-09-10"
	},
	{
		feriado: "nossa-senhora-aparecida",
		min: "2019-10-11",
		max: "2019-10-15"
	},
	{
		feriado: "proclamacao-da-republica",
		min: "2019-11-14",
		max: "2019-11-19"
	},
	{
		feriado: "ano-novo",
		min: "2018-12-26",
		max: "2019-01-07"
	},
	{
		feriado: "carnaval",
		min: "2019-03-01",
		max: "2019-03-11"
	},
	{
		feriado: "semana-santa-pascoa",
		min: "2019-04-18",
		max: "2019-04-22"
	}
];
