const MAX_MONTHS_CALENDAR = 12;
const MIN_DESKTOP_WIDTH = 991;
import flatpickr from "flatpickr";
import flatpickrLocale from "flatpickr/dist/l10n/pt.js";
import { removeActiveField } from "./generalDomUtils";
import { isMobile, $$, $ } from "./common";
import moment from "moment";

/**
 * Preenche um numero com zeros ou um caractere a ser escolhido
 * @param {Number} n a ser convertido
 * @param {Number} width numero de zeros a serem adicionados
 * @param {String} z Caractere preenchedor (senao preenchido, será zero)
 */
const pad = (n, width = 2, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * verifica se existe uma string no formato YYYY-MM-DD
 * @param {String} strDate data
 */
export const dateFormatMatches = strDate => {
	const date = moment(strDate);
	return strDate
		? strDate.toString().match(/\d{4}\-\d{2}\-\d{2}/) !== null && date.isValid()
		: false;
};

/**
 * retorna um objeto Date do Javascript com a data mínima aceitável
 * (hoje + 1d - depois das 21h - hoje + 2d)
 */

export const minimalAcceptableDate = () => {
	const date = new Date()
	date.setHours(0, 0, 0, 0);
	return date;
};

/**
 * Converte data javascript em YYYY-MM-DD
 * @param {Date} jsDate objeto javascript
 */
export const jsDateToStrDate = jsDate =>
	`${
		jsDate
			? jsDate.getFullYear() + "-" + pad(jsDate.getMonth() + 1) + "-" + pad(jsDate.getDate())
			: ""
	}`;

export const jsDatetoBRStrDate = jsDate =>
	jsDateToStrDate(jsDate)
		.split("-")
		.reverse()
		.join("/");

/**
 * Transforma um strDate (YYYY-MM-DD) em um objeto Date do Javascript
 * @param {String} strDate data em formato YYYY-MM-DD
 */
export const strDateToJsDate = strDate => new Date(`${strDate} 00:00:00`.replace(/-/g, "/"));

const isDateObj = dateObj => dateObj instanceof Date;

/**
 * Valida se a data recebida em referenceDateObj.date é uma data válida, dado o contexto
 * da aplicação:
 *  * estar entre a data mínima e máxima
 *  * se for data de inbound, ser menor que a data de outbound
 * @param {Date} minDate data mínima
 * @param {Date} maxDate data máxima
 * @param {Object} referenceDateObj objeto contendo date:Object, outboundDate:Object
 * @param {Boolean} isFirstStretch
 * @returns {String} data no formato dd/mm/yyyy ou string vazia
 */
export const dateFromStretchValidator = (minDate, maxDate, referenceDateObj, isOutbound) => {
	let { date, outboundDate, minimalDateFn } = referenceDateObj;

	if (
		date instanceof Date === false ||
		outboundDate instanceof Date === false ||
		date.toString().toLowerCase() === "invalid date" ||
		outboundDate.toString().toLowerCase() === "invalid date" ||
		date.getTime() < minDate.getTime() ||
		(date.getTime() < outboundDate.getTime() && !isOutbound) ||
		date.getTime() > maxDate.getTime()
	) {
		return isOutbound ? jsDatetoBRStrDate(minDate) : "";
	} else {
		return jsDatetoBRStrDate(date);
	}
};
