import {strDateToInt} from '../utils/common';
export default class StretchValidators{
    
        constructor(departureDate, arrivalDate){
            this.departureDate = departureDate;
            this.arrivalDate = arrivalDate;
            this.errorsCount = 0;
            this.errorsData = {};

            this.validate = () => {
                this.errorsData = {};
                this.departureIsEmpty();
                return this.errorsData;
            }
        }
    
        departureIsEmpty(){

            console.log(this.departureDate);
            try{
                if(this.departureDate.datepickerDate == ""){
                    this.errorsData = {
                        ...this.errorsData,
                        departureIsEmptyMessage: "Selecione uma data de partida"
                    }
                }
            }catch(e){
                console.log('erro verificando existencia de data de partida ', e);
                this.errorsCount++;
            }
        }

}