import PubSub from "pubsub-js";
import "../../../shared/pubsub-triggers";

export const trackBuyProcessInit = action => {
    PubSub.publish("dataLayer", {
        event: "gaEvent",
        gaEventCategory: "Processo de compra",
        gaEventAction: action,
        gaEventLabel: "",
        gaEventValue: ""
    });
}

export const trackAutoCompleteInit = (stretch, hasResultData, term) => {
    PubSub.publish("dataLayer", {
        event: "gaEvent",
        gaEventCategory: "Home",
        gaEventAction: stretch === "from" ? "Origem" : "Destino",
        gaEventLabel: hasResultData,
        gaEventDetail: term
    });
}

export const trackSearchesWithHotel = (hasHotel, isInHomePage) => {
	const isInSearchResultsPage = window.location.href.match("/busca-passagens-aereas/(RT|OW)") !== null
    const gaEventCategory = hasHotel && isInHomePage || !hasHotel && isInHomePage 
    ? "Página Inicial" 
    : hasHotel && isInSearchResultsPage || !hasHotel && isInSearchResultsPage
    ? "Resultados de busca" 
    : hasHotel ? "Buscou com hotel em outra página" : "Buscou sem hotel em outra página";
	const gaEventLabel = hasHotel  ? "Com hotel" : "Sem hotel";

	PubSub.publish("dataLayer", {
		event: "gaEvent",
		gaEventCategory: gaEventCategory,
		gaEventAction: "Busca",
		gaEventLabel: gaEventLabel,
		gaEventValue: ""
	});
}
