import {
	dateFormatMatches,
	minimalAcceptableDate,
	jsDateToStrDate,
	strDateToJsDate,
	jsDatetoBRStrDate,
	dateFromStretchValidator
} from "./utils/datepickerDomUtils";
import { $ } from "./utils/common";
import { retrieveDate } from "./utils/localStorageUtils";
import VMasker from "vanilla-masker";
import flatpickr from "flatpickr";
import flatpickrLocale from "flatpickr/dist/l10n/pt.js";
import { removeActiveField } from "./utils/generalDomUtils";

export default class DatePickerWrapper {
	constructor(input, localStorageAttr, inboundCalendar) {
		this.input = input;
		this.stretch = localStorageAttr;
		this.calendarInstance;
		this.selectedDate;
		this.inboundCalendar = inboundCalendar;
		this.calendarButton = $(`#datepicker-${this.stretch}-button`);
		// this.calendarInput = $(`#datepicker-${this.stretch}-input`);
		this.calendarInput = input;

		VMasker($(`#${this.input.id}`)).maskPattern("99/99/9999");
		this.setInitialDate(localStorageAttr);
		this.init(localStorageAttr);
	}

	removeStretch(event) {
		if (this.inboundCalendar) {
			this.inboundCalendar.clear();
		}
	}

	openCalendar(event) {
		event.preventDefault();
		this.calendarInstance.toggle();
	}

	resolveDate(minDate, maxDate, isOutbound, event) {
		const timestampInputValue = this.input.value
			.split("/")
			.reverse()
			.join("-");

		/*const referenceDateObj = {
			date: strDateToJsDate(timestampInputValue),
			outboundDate: strDateToJsDate(
				$("#search_form_outbound_date")
					.value.split("/")
					.reverse()
					.join("-")
			)
		};*/

		if (dateFormatMatches(timestampInputValue)) {
			//this.input.value = dateFromStretchValidator(minDate, maxDate, referenceDateObj, isOutbound);
			this.selectedDate = strDateToJsDate(
				this.input.value
					.split("/")
					.reverse()
					.join("-")
			);
			this.calendarInstance.setDate(this.selectedDate, true);
		}

		//se deu blur no calendário de volta com uma data inválida
		//(string em branco, inválida ou incompleta), deixa o calendário em branco
		/*if (!dateFormatMatches(timestampInputValue)) {
			if (!isOutbound) {
				this.selectedDate = "";
				this.input.value = "";
				this.calendarInstance.setDate(timestampInputValue.outboundDate, true);
			} else {
				this.selectedDate = minimalAcceptableDate();
				this.input.value = jsDatetoBRStrDate(minimalAcceptableDate());
				this.calendarInstance.setDate(this.selectedDate, true);
			}
		} else {
			console.log("selected date: ", this.selectedDate);
			this.input.value = dateFromStretchValidator(minDate, maxDate, referenceDateObj, isOutbound);

			if (this.input.value === "") {
				return;
			}
			this.selectedDate = strDateToJsDate(
				this.input.value
					.split("/")
					.reverse()
					.join("-")
			);
			this.calendarInstance.setDate(this.selectedDate, true);
			//tenta verificar se a nova data de outbound é maior que a data de inbound
			if (isOutbound) {
				let outboundTimestampDate = parseInt(
					this.input.value
						.split("/")
						.reverse()
						.join("")
				);
				let inboundTimestampDate = parseInt(
					$("#search_form_inbound_date")
						.value.split("/")
						.reverse()
						.join("")
				);
				let outboundDate = strDateToJsDate(
					$("#search_form_outbound_date")
						.value.split("/")
						.reverse()
						.join("-")
				);

				//se inbound estiver em branco, sai da função
				if (isNaN(inboundTimestampDate)) {
					return;
				}
				//se nova data de outbound for maior que a de inbound, a data de inbound
				//vai ser a mesma que outbound
				if (outboundTimestampDate > inboundTimestampDate) {
					inboundTimestampDate = outboundTimestampDate;
					this.inboundCalendar.setDate(strDateToJsDate(outboundDate));
					$("#search_form_inbound_date").value = $("#search_form_outbound_date").value;
				} else {
					this.calendarInstance.setDate(this.selectedDate, true);
				}
			}
		}*/
	}

	/**
	 * inicia as instâncias do flatpicker baseado na stretch passada
	 * @argument {String} stretch tipo do trecho (departure ou arrival)
	 */
	init(stretch) {
		const _this = this;
		const minDate = minimalAcceptableDate();
		const minLocalStorageDate = retrieveDate("departure")
			? new Date(`${retrieveDate("departure")} GMT-0300`)
			: undefined;
		const maxDate = minimalAcceptableDate();
		maxDate.setDate(maxDate.getDate() + 329);

		this.calendarButton.onclick = this.openCalendar.bind(this);
		//this.input.onfocus = this.openCalendar.bind(this);
		this.input.onblur = this.resolveDate.bind(this, minDate, maxDate, this.stretch == "departure");

		const removeStretch = $("#remove-stretch");
		removeStretch.onclick = this.removeStretch.bind(this);

		this.calendarInstance = flatpickr(`#${this.calendarInput.id}`, {
			minDate,
			maxDate,
			locale: "pt",
			disableMobile: "true", // desabilita o datepicker nativo no browser mobile
			name: `#${this.calendarInput.id}`,
			dateFormat: "d/m/Y",
			appendTo: document.querySelector("#search-form-section .input-group"),
			static: true,
			allowInput: true,
			onReady: function(selectedDates, dateStr, instance) {
				if (_this.selectedDate) {
					instance.setDate(_this.selectedDate);
				}

				if (stretch === "arrival") {
					instance.set("minDate", minLocalStorageDate || minDate);
				}
			},

			onClose: function() {
				if (stretch == "arrival") {
					$("#hiddenbtn").focus();
				}
			},

			onOpen: function() {
				removeActiveField();
			},
			onChange: function(selectedDates, dateStr, instance) {
				//evita que o calendário de outbound fique em branco
				//caso não existam datas selecionadas

				if (_this.stretch == "departure") {
					if (selectedDates[0]) {
						_this.input.value = jsDatetoBRStrDate(selectedDates[0]);
						_this.selectedDate = selectedDates[0];
					}
				} else {
					_this.input.value = jsDatetoBRStrDate(selectedDates[0]);
					_this.selectedDate = selectedDates[0];
				}

				if (_this.inboundCalendar) {
					const inboundCalendar = _this.inboundCalendar;
					const inboundInput = $(`#search_form_inbound_date`);
					const outboundInput = $(`#search_form_outbound_date`);
					let inboundTimestampDate = parseInt(
						inboundInput.value
							.split("/")
							.reverse()
							.join("")
					);
					let outboundTimestampDate = parseInt(
						outboundInput.value
							.split("/")
							.reverse()
							.join("")
					);

					inboundCalendar.set("minDate", _this.selectedDate);

					if (inboundInput.value !== "" && outboundTimestampDate > inboundTimestampDate) {
						inboundInput.value = jsDatetoBRStrDate(selectedDates[0]);
					}
				}
			}
		});
	}
	/**
	 * seta a data inicial baseado na stretch passada
	 * @argument {String} stretch tipo do trecho (departure ou arrival)
	 */
	setInitialDate(stretch) {
		this.selectedDate = retrieveDate(stretch);

		if (!dateFormatMatches(this.selectedDate)) {
			if (stretch == "departure") {
				this.selectedDate = minimalAcceptableDate();
			} else {
				this.selectedDate = undefined;
			}
		} else {
			//se tiver data no localStorage, verifica se a data é maior que hoje
			let localStorageDate = strDateToJsDate(this.selectedDate);
			const minimalDate = minimalAcceptableDate();

			//se a data do localStorage for menor, usa a data mínima (hoje + 1 dia)
			if (localStorageDate.getTime() < minimalDate.getTime()) {
				localStorageDate = minimalDate;

				if (stretch == "departure") {
					this.selectedDate = localStorageDate;
				} else {
					this.selectedDate = undefined;
				}
			} else {
				this.selectedDate = localStorageDate;
			}
		}

		this.input.value = jsDatetoBRStrDate(this.selectedDate);
	}

	setInput(inputElm) {
		this.inputElm = inputElm;
	}

	setData(selectedDate) {
		this.selectedDate = selectedDate;
	}

	getInput() {
		return this.inputElm;
	}

	getData() {
		return this.selectedDate;
	}

	setDatePickerTriggers(removeStretch) {
		let selectedDate;

		if (removeStretch) {
			removeStretch.onclick = event => {
				event.preventDefault();
				this.setData({});
			};
		}
	}
}
