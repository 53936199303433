import { ajax } from "nanoajax";
import slugify from "slugify";
import Swiper from "swiper";
import lozad from 'lozad'
import { fallbackBestPrices } from "../../shared/fallbackBestPrices";
import { airports } from "../../shared/airports";
import { BFF_API, LEGACY_API } from "../../shared/constants";
import { formatCurrency, shuffleArray } from "../../shared/utils";
import { format } from "path";

const observer = lozad();

const openInNewTab = url => {
	const win = window.open(url, "_blank");
	win.focus();
};

const getSlug = iata => {
	if (!iata) return null;

	const data = Object.keys(airports).map(function(key) {
		return airports[key];
	});
	const item = data.find(item => item[1] == iata);

	if (!item) return null;

	let city = item[0];
	let country = item[2];

	city = city.split("-")[0];
	city = city.split("/")[0];
	city = city.split(",")[0];

	country = country.split("-")[0];
	country = country.split("/")[0];
	country = country.split(",")[0];

	city = slugify(city, { lower: true });
	country = slugify(country, { lower: true });

	const slug = `${city}-${country}`;

	return slug;
};

const Card = function(
	{
		origin,
		destination,
		destination_code,
		origin_code,
		best_price_airline_w_taxes: airlinePrice,
		best_price_mm_w_taxes: mmPrice,
		url: href
	},
	ASSETS_URL = ""
) {
	const originSlug = getSlug(origin_code);
	const destinationSlug = getSlug(destination_code);
	const card = {};

	card.originCode = origin_code;
	card.destinationCode = destination_code;
	card.originSlug = originSlug;
	card.destinationSlug = destinationSlug;
	card.destinationName = destination ? slugify(destination, { lower: true }) : "default-small";
	card.moreHref =
		card.originSlug && card.destinationSlug
			? `/passagens-aereas/${card.originSlug}/${card.destinationSlug}`
			: null;

	let [, , , , oneWay, , , goDate, backDate] = href.split("/");

	card.oneWay = oneWay == "OW";

	card.goDate = goDate
		.split("-")
		.splice(1, 2)
		.reverse()
		.join("/");

	card.backDate = card.oneWay
		? ""
		: backDate
				.split("-")
				.splice(1, 2)
				.reverse()
				.join("/");

	card.save =
		mmPrice && airlinePrice ? Math.floor((airlinePrice - mmPrice) * 100 / airlinePrice) : 0;

	href = href.replace("https://www.maxmilhas.com.br", "");

	card.getHtml = () => {
		return `<div class="trend-card"><a type="button" rel="nofollow" href="${href}" target="_blank">
	<div class="trend-card__section trend-card__section--image" title="${destination ? destination : "Aeroporto"}">
		<img class="lozad" data-src="${ASSETS_URL}/destinos/city-without-filter/${card.destinationName}-01-440x250.jpg" onerror="this.onerror=null;this.src='${ASSETS_URL}/destinos/default-small.jpg';" width="440" height="250" alt="${destination}" />
		<div class="trend-card__date">
			<span>Ida <strong>${card.goDate}</strong></span>
			${
				card.oneWay
					? ""
					: `<i><img class="lozad" data-src="${ASSETS_URL}/ui-kit/down.svg"></i>
				<span>Volta <strong>${card.backDate}</strong></span>`
			}
		</div>
	</div>
	<div class="trend-card__section trend-card__section--trip">
		<div class="trend-card__plane">
			<i class="leg-ball leg-ball--open"></i>
			<i class="leg-path"></i>
			<i class="leg-plane"><img class="lozad" data-src="${ASSETS_URL}/ui-kit/plane.svg"></i>
			<i class="leg-ball leg-ball--closed"></i>
		</div>
		<div class="trend-card__info">
			<div class="trend-card__leg">
				<span>Passagens de:</span>
				<strong>${origin ? origin : ""}
				<span class="sig hidden-sm">${origin_code}</span></strong>
			</div>
			<div class="trend-card__leg">
				<span>Para:</span>
				<strong>${destination ? destination : ""}
				<span class="sig hidden-sm">${destination_code}</span></strong>
			</div>
		</div>
	</div>
	<div class="trend-card__section trend-card__section--cta clearfix">
		${
			!mmPrice
				? ""
				: `
				<div class="trend-card__tag" ${card.save <= 0 ? 'style="opacity: 0; visibility: hidden;"' : ""}>
					<span class="left-edge"></span>Economize ${card.save}%<span class="right-edge"></span>
				</div>
				`
		}
		${
			!mmPrice
				? ""
				: `<div class="trend-card__price">
				<span>A partir de:</span>
				<strong>${formatCurrency(mmPrice, "R$ ")}</strong>
			</div>`
		}
		<button type="button" rel="nofollow" href="${href}" class="trend-card__cta">
			<span class="hidden-v2-1">Comprar<span class="visible-sm" style="margin-left: 4px;">agora</span></span>
			<span class="show-v2-1">Pesquisar</span>
		</button>
	</div>
	</a></div>
	<a	target="_blank"
		${!card.moreHref ? 'style="opacity: 0; visibility: hidden;" ' : ""}
		href="${card.moreHref}"
		class="trend-card__more"><span class="show-v2-1">Ver mais voos</span></a>`;
	};

	return card;
};

const Trends = async function(
	node,
	airportsArray,
	{
		size = 12,
		slider = true,
		mindate = null,
		maxdate = null,
		route = "best-prices/flights",
		errorCallback,
		tripType = "OW"
	} = {}
) {
	if (!node) throw Error("You should pass a node to mount Trends!");
	node.classList.add("trends-box");

	const ASSETS_URL = node.getAttribute("data-url-asset");

	const airportsRequests = [];

	const getData = () => {
		if (Array.isArray(airportsArray)) {
			// [].forEach(airport => {
			airportsArray.forEach(airport => {
				airportsRequests.push(
					new Promise((resolve, reject) => {
						const dateFilter =
							mindate && maxdate
								? `&min_outbound_date=${mindate}&max_outbound_date=${maxdate}`
								: "";
						const url = `${BFF_API}/best-prices/flights?size=${size}&from=${airport}&exclusivo_maxmilhas=false&trip_type=${tripType}${dateFilter}`;

						ajax({ url }, (code, res) => {
							if (code === 200) {
								try {
									const result = JSON.parse(res);
									resolve(result);
								} catch (error) {
									console.error("erro from trends: ", error);
									reject();
								}
							}
						});
					})
				);
			});
		}
	};

	const getHtml = data => {
		let html = `<div class="swiper-wrapper">`;

		html += data
			.sort((aCard, bCard) => bCard.save - aCard.save)
			.slice(0, size)
			.map(card => `<div class="swiper-slide">${card.getHtml()}</div>`)
			.join("");
		html += "</div>";
		html += `<div class="swiper-pagination"></div>`;
		html += `<div class="swiper-button-prev"><i class="icon-arrow-left"></i></div >`;
		html += `<div class="swiper-button-next"><i class="icon-arrow-right"></i></div >`;
		return html;
	};

	const mount = html => (node.innerHTML = html);

	const load = async v => {
		switch (v) {
			default:
				node.classList.remove("v2-1");
				await init("OW");
				const anchors = Array.from(node.querySelectorAll(".trend-card > a"));
				anchors.forEach(anchor => anchor.removeAttribute("href"));
				const buttons = Array.from(node.querySelectorAll(".trend-card__cta"));
				buttons.forEach(
					button => (button.onclick = () => openInNewTab(button.attributes.href.value))
				);
		}
		node.swiper.update();
	};

	const mountCards = data => {
		const cards = [];

		for (let i = 0; i < data.length; i++) {
			let card = Card(data[i], ASSETS_URL);
			cards.push(card);
		}

		const html = getHtml(cards);
		mount(html);

		node.load = load;

		observer.observe();

		if (slider) {
			node.classList.add("swiper-container");
			new Swiper(node, {
				slidesPerView: 6,
				slidesPerGroup: 6,
				spaceBetween: 30,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				breakpoints: {
					1919: {
						slidesPerGroup: 4,
						slidesPerView: 4
					},
					1199: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					991: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					767: {
						slidesPerGroup: 1,
						slidesPerView: 1
					}
				}
			});
		}
	};

	const init = async () => {
		getData();

		if (airportsRequests) {
			Promise.all(airportsRequests).then(cardData => {
				let data = [];
				let cards = [];

				data = cardData.map(item => {
					if (!Array.isArray(item)) return [];

					return item;
				});

				data = [].concat.apply([], data);

				if (data.length < 3) {
					try {
						const dateFilter =
							mindate && maxdate
								? `&min_outbound_date=${mindate}&max_outbound_date=${maxdate}`
								: "";
						const url = `${BFF_API}/best-prices/flights?size=12&exclusivo_maxmilhas=false&trip_type=${tripType}${dateFilter}`;

						ajax({ url }, (code, res) => {
							if (code === 200) {
								try {
									const result = JSON.parse(res);
									if (Array.isArray(result) && result.length > 0) {
										mountCards(result);
									} else return errorCallback ? errorCallback() : mountCards(fallbackBestPrices());
								} catch (error) {
									console.error("error from trends: ", error);
								}
							}
						});
					} catch (err) {
						mountCards(fallbackBestPrices());
					}
				} else {
					mountCards(data);
				}
			});
		} else {
			return errorCallback ? errorCallback() : mountCards(fallbackBestPrices());
		}
	};

	try {
		init();
	} catch (e) {
		console.error(e);
	}
};

export default Trends;
