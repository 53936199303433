import moment from "moment";
import { feriadosData } from "./feriadosData";
import { shuffleArray } from "./utils";

const bodyElement = document.querySelector("body");
const classesBody = bodyElement.classList;
const dateForUrl = [];
const rangeDatesFromHoliday = [];
let i = 0;
let nameFromHoliday = null;

// Verifico se é pagina de feriado
if (classesBody.contains("holidays")) {
	nameFromHoliday = classesBody[2];

	if (nameFromHoliday == "black-friday" || nameFromHoliday == "dia-mundial-das-milhas") {
		nameFromHoliday = null;
	}
}

// Crio 8 datas a partir da data do feriado ou proximos dias
if (!nameFromHoliday) {
	for (i = 1; i < 9; i++) {
		const nextDate = moment()
			.add(i, "days")
			.format("YYYY-MM-DD");
		dateForUrl.push(nextDate);
	}
} else {
	feriadosData.map(item => {
		if (item.feriado == nameFromHoliday) {
			const dayMin = moment(item.min, "YYYY-MM-DD");
			const dayMax = moment(item.max, "YYYY-MM-DD");
			const daysDiff = dayMax.diff(dayMin, "days");

			for (i = 0; i <= daysDiff; i++) {
				let dateFromHoliday = dayMin
					.clone()
					.add(i, "days")
					.format("YYYY-MM-DD");
				rangeDatesFromHoliday.push(dateFromHoliday);
			}
		}
	});

	for (i = 1; i < 9; i++) {
		let dateShuffle = shuffleArray(rangeDatesFromHoliday);
		let dateExistOnRange = dateForUrl.includes(dateShuffle[0]);

		// Se range for maior que 8, crio um array sem repeticoes.
		if (rangeDatesFromHoliday.length > 8) {
			while (dateExistOnRange == true) {
				dateShuffle = shuffleArray(rangeDatesFromHoliday);
				dateExistOnRange = dateForUrl.includes(dateShuffle[0]);
			}
		}

		dateForUrl.push(dateShuffle[0]);
	}
}

const mainCitys = [
	{
		name: "Rio de Janeiro",
		iata: "RIO"
	},
	{
		name: "São Paulo",
		iata: "SAO"
	},
	{
		name: "Belo Horizonte",
		iata: "BHZ"
	},
	{
		name: "Fortaleza",
		iata: "FOR"
	},
	{
		name: "Salvador",
		iata: "SSA"
	},
	{
		name: "Brasília",
		iata: "BSB"
	},
	{
		name: "Recife",
		iata: "REC"
	},
	{
		name: "Natal",
		iata: "NAT"
	}
];

export const fallbackBestPrices = () => {
	const origin = shuffleArray(mainCitys);
	let destination = shuffleArray(mainCitys);

	let i;
	let payloadFallback = [];

	for (i = 0; i < 8; i++) {
		if (origin[i]["name"] == destination[i]["name"]) {
			destination = shuffleArray(mainCitys);
		}

		let trip = {
			trip: `${origin[i]["iata"]}-${destination[i]["iata"]}`,
			destination_code: destination[i]["iata"],
			origin_code: origin[i]["iata"],
			url: `https://www.maxmilhas.com.br/busca-passagens-aereas/OW/${origin[i]["iata"]}/${
				destination[i]["iata"]
			}/${dateForUrl[i]}/1/0/0/EC`,
			best_price_airline_w_taxes: 0,
			best_price_mm_w_taxes: 0,
			best_price_mm: 0,
			best_price_airline: 0,
			origin: origin[i]["name"],
			destination: destination[i]["name"]
		};

		payloadFallback.push(trip);
	}

	return payloadFallback;
};
