import { showField, removeActiveField } from "./utils/generalDomUtils";
import { bindUpdatePassengers } from "./utils/passengersDomUtils";
import {
	updatePassengers,
	getPassengersResume,
	updatePassengersDataVerifiers
} from "./utils/passengersUpdaterUtils";
import { retrievePassengers } from "./utils/localStorageUtils";
import { $$, $, decorateWithError, removeErrorDecoration } from "./utils/common";

const DEFAULT_ERROR_MESSAGE =
	"Existem erros na seleção de passageiros. Corrija-os para prosseguir.";

export default class PassengersWrapper {
	constructor(elm) {
		this.elm = elm;
		this.parent = this.elm.parentElement.parentElement;
		this.passengersData = retrievePassengers();
		this.setTriggers(this.elm, this.parent);
		this.updateView();
	}

	setTriggers(elm, parent) {
		const _this = this;

		function togglePassengersWrapper(e) {
			e.preventDefault();
			e.stopPropagation();
			showField(this, parent);
			$("#passengers-adults").focus();
		}

		const button = document.getElementById("hiddenbtn");
		button.addEventListener("click", togglePassengersWrapper);

		document.getElementById("confirm-passenger-selection").onclick = function(event) {
			event.preventDefault();
			removeActiveField();
		};

		elm.onclick = function(event) {
			showField(this, parent);
			if (_this.parent.className.match(/\shas-error/g) !== null) {
				_this.showWrapperError();
			}
		};

		$$(".passengers-cabin select").map(select => {
			const value = select.name;
			select.value = this.passengersData[value];

			const _this = this;
			select.onchange = function(event) {
				_this.passengersData = updatePassengersDataVerifiers({
					..._this.passengersData,
					cabin: this.value
				});
				_this.updateView();
			};
		});

		$$(".passengers-info select").map(select => bindUpdatePassengers(select, this));
	}

	getData() {
		return {
			adults: parseInt(this.passengersData.adults),
			children: parseInt(this.passengersData.children),
			infants: parseInt(this.passengersData.infants),
			cabin: this.passengersData.cabin
		};
	}

	updatePassengersData(passengerType, value) {
		this.passengersData = updatePassengers(this.passengersData, passengerType, value);
	}

	clearErrors(infantsWrapper, executiveClassWrapper) {
		$$(
			".babies-on-executive-class-error, .infants-outnumber-adults-error, .quantity-exceeds-limit-error"
		).map(elm => (elm.className += " hidden"));

		this.parent.className = this.parent.className.replace(/\shas-error/g, "");
		removeErrorDecoration(infantsWrapper);
		removeErrorDecoration(executiveClassWrapper);
		removeErrorDecoration(this.parent);
	}

	fillPassengers() {
		$$(".passengers-count").map(passengerNode => {
			const passengerType = passengerNode.getAttribute("data-passenger-type");
			passengerNode.querySelector(".passengers-count-number").innerHTML = this.passengersData[
				passengerType
			];
		});
	}

	showWrapperError() {
		decorateWithError(this.parent, DEFAULT_ERROR_MESSAGE);
		this.parent.setAttribute("data-show", false);
	}

	updateView() {
		const infantsWrapper = $(".passengers-info > div:nth-child(3)");
		const executiveClassWrapper = $(".passengers-cabin > div");

		this.clearErrors(infantsWrapper, executiveClassWrapper);
		this.fillPassengers();

		if (this.passengersData.infantsOutnumberAdults) {
			this.parent.className = this.parent.className.replace(/has-error/g, "") + " has-error";
			decorateWithError(infantsWrapper, "O número máximo de bebês é de 1 para cada adulto");
			this.showWrapperError();
		}

		if (this.passengersData.quantityExceedsLimit) {
			$$(".quantity-exceeds-limit-error").map(
				elm => (elm.className = elm.className.replace(/\shidden/g, ""))
			);
		}

		const passengersResumeObj = getPassengersResume(this.passengersData);

		this.parent.querySelector(".passengers-total").innerHTML = passengersResumeObj.amount;
		this.parent.querySelector(".passengers-label").innerHTML = passengersResumeObj.label;
		this.parent.querySelector(".cabin").innerHTML = passengersResumeObj.cabin;
	}
}
