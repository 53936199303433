export const airports = {
	NYC: ["Nova Iorque - Todos", "NYC", "US"],
	BHZ: ["Belo Horizonte - Todos", "BHZ", "BR"],
	RIO: ["Rio de Janeiro - Todos", "RIO", "BR"],
	BER: ["Berlim - Todos", "BER", "DE"],
	BUE: ["Buenos Aires - Todos", "BUE", "AR"],
	SAO: ["São Paulo - Todos", "SAO", "BR"],
	LON: ["Londres - Todos", "LON", "GB"],
	MIL: ["Milão - Todos", "MIL", "IT"],
	SHA: ["Shanghai - Todos", "SHA", "CN"],
	CHI: ["Chicago - Todos", "CHI", "US"],
	STO: ["Estocolmo - Todos", "STO", "SE"],
	MOW: ["Moscou - Todos", "MOW", "RU"],
	PAR: ["Paris - Todos", "PAR", "FR"],
	TYO: ["Tóquio - Todos", "TYO", "JP"],
	JFK: ["Nova Iorque - John F. Kennedy Intl", "JFK", "US"],
	LGA: ["Nova Iorque - LaGuardia", "LGA", "US"],
	EWR: ["Nova Iorque - Newark Liberty Intl", "EWR", "US"],
	SWF: ["Nova Iorque - Stewart Intl", "SWF", "US"],
	CNF: ["Belo Horizonte - Confins Intl", "CNF", "BR"],
	PLU: ["Belo Horizonte - Pampulha", "PLU", "BR"],
	VCP: ["Campinas Viracopos", "VCP", "BR"],
	GIG: ["Rio de Janeiro - Galeão Intl", "GIG", "BR"],
	SDU: ["Rio de Janeiro - Santos Dumont", "SDU", "BR"],
	CGH: ["São Paulo - Congonhas", "CGH", "BR"],
	GRU: ["São Paulo - Guarulhos Intl", "GRU", "BR"],
	SXF: ["Berlim - Schoenefeld", "SXF", "DE"],
	TXL: ["Berlim - Tegel", "TXL", "DE"],
	AEP: ["Buenos Aires - Aeroparque", "AEP", "AR"],
	EZE: ["Buenos Aires - Ezeiza Intl", "EZE", "AR"],
	LCY: ["Londres - City Airport", "LCY", "GB"],
	LGW: ["Londres - Gatwick", "LGW", "GB"],
	LHR: ["Londres - Heathrow", "LHR", "GB"],
	BGY: ["Milão - Bergamo/Orio al Serio", "BGY", "IT"],
	LIN: ["Milão - Linate", "LIN", "IT"],
	MXP: ["Milão - Malpensa Intl", "MXP", "IT"],
	MDW: ["Chicago - Midway Intl", "MDW", "US"],
	ORD: ["Chicago - O'Hare Intl", "ORD", "US"],
	ARN: ["Estocolmo - Arlanda", "ARN", "SE"],
	DME: ["Moscou - Domodedovo", "DME", "RU"],
	SVO: ["Moscou - Sheremetyevo", "SVO", "RU"],
	CDG: ["Paris - Charles de Gaulle Intl", "CDG", "FR"],
	ORY: ["Paris - Orly", "ORY", "FR"],
	PVG: ["Shanghai - Pudong Intl", "PVG", "CN"],
	HND: ["Tóquio - Haneda Intl", "HND", "JP"],
	NRT: ["Tóquio - Narita Intl", "NRT", "JP"],
	TXF: ["Aeroporto de Teixeira de Freitas", "TXF", "BR"],
	AFL: ["Alta Floresta", "AFL", "BR"],
	ATM: ["Altamira", "ATM", "BR"],
	AJU: ["Aracaju", "AJU", "BR"],
	ARU: ["Araçatuba", "ARU", "BR"],
	AUX: ["Araguaína", "AUX", "BR"],
	AQA: ["Araraquara", "AQA", "BR"],
	AAX: ["Araxá", "AAX", "BR"],
	BAZ: ["Barcelos", "BAZ", "BR"],
	BRA: ["Barreiras", "BRA", "BR"],
	BRB: ["Barreirinhas", "BRB", "BR"],
	JTC: ["Bauru", "JTC", "BR"],
	BEL: ["Belém", "BEL", "BR"],
	BVB: ["Boa Vista", "BVB", "BR"],
	BYO: ["Bonito", "BYO", "BR"],
	BSB: ["Brasília", "BSB", "BR"],
	CFB: ["Cabo Frio", "CFB", "BR"],
	CFC: ["Caçador", "CFC", "BR"],
	OAL: ["Cacoal", "OAL", "BR"],
	CLV: ["Caldas Novas", "CLV", "BR"],
	CPV: ["Campina Grande", "CPV", "BR"],
	CGR: ["Campo Grande", "CGR", "BR"],
	CAW: ["Campos dos Goytacazes", "CAW", "BR"],
	CKS: ["Carajás", "CKS", "BR"],
	CAC: ["Cascavel", "CAC", "BR"],
	CXJ: ["Caxias do Sul", "CXJ", "BR"],
	XAP: ["Chapecó", "XAP", "BR"],
	CIZ: ["Coari", "CIZ", "BR"],
	UNA: ["Comandatuba", "UNA", "BR"],
	CMG: ["Corumbá", "CMG", "BR"],
	CCM: ["Criciúma", "CCM", "BR"],
	CZS: ["Cruzeiro do Sul", "CZS", "BR"],
	CGB: ["Cuiabá", "CGB", "BR"],
	CWB: ["Curitiba", "CWB", "BR"],
	DIQ: ["Divinópolis", "DIQ", "BR"],
	DOU: ["Dourados", "DOU", "BR"],
	ERN: ["Eirunepe", "ERN", "BR"],
	ERM: ["Erechim", "ERM", "BR"],
	FEC: ["Feira de Santana", "FEC", "BR"],
	FEN: ["Fernando de Noronha", "FEN", "BR"],
	FLN: ["Florianópolis", "FLN", "BR"],
	FBA: ["Fonte Boa", "FBA", "BR"],
	FOR: ["Fortaleza", "FOR", "BR"],
	IGU: ["Foz do Iguaçu", "IGU", "BR"],
	FRC: ["Franca", "FRC", "BR"],
	FBE: ["Francisco Beltrão", "FBE", "BR"],
	GYN: ["Goiânia", "GYN", "BR"],
	GVR: ["Governador Valadares", "GVR", "BR"],
	GPB: ["Guarapuava", "GPB", "BR"],
	HUW: ["Humaitá", "HUW", "BR"],
	IOS: ["Ilhéus", "IOS", "BR"],
	IMP: ["Imperatriz", "IMP", "BR"],
	IPN: ["Ipatinga", "IPN", "BR"],
	ITB: ["Itaituba", "ITB", "BR"],
	JJG: ["Jaguaruna - Humberto Ghizzo Bortoluzzi", "JJG", "BR"],
	JPR: ["Ji-Parana", "JPR", "BR"],
	JCB: ["Joacaba", "JCB", "BR"],
	JPA: ["João Pessoa", "JPA", "BR"],
	JOI: ["Joinville", "JOI", "BR"],
	JDO: ["Juazeiro do Norte", "JDO", "BR"],
	JDF: ["Juiz de Fora", "JDF", "BR"],
	IZA: ["Juiz de Fora - Zona da Mata", "IZA", "BR"],
	LEC: ["Lencois Chapada Diamantina", "LEC", "BR"],
	LDB: ["Londrina", "LDB", "BR"],
	MEA: ["Macaé", "MEA", "BR"],
	MCP: ["Macapá", "MCP", "BR"],
	MCZ: ["Maceió", "MCZ", "BR"],
	MAO: ["Manaus", "MAO", "BR"],
	MAB: ["Marabá", "MAB", "BR"],
	MII: ["Marília", "MII", "BR"],
	MGF: ["Maringá", "MGF", "BR"],
	MQH: ["Minaçu", "MQH", "BR"],
	MOC: ["Montes Claros", "MOC", "BR"],
	NAT: ["Natal", "NAT", "BR"],
	NVT: ["Navegantes", "NVT", "BR"],
	PMW: ["Palmas", "PMW", "BR"],
	PIN: ["Parintins", "PIN", "BR"],
	PHB: ["Parnaíba", "PHB", "BR"],
	PFB: ["Passo Fundo", "PFB", "BR"],
	POJ: ["Patos de Minas", "POJ", "BR"],
	PAV: ["Paulo Afonso", "PAV", "BR"],
	PET: ["Pelotas", "PET", "BR"],
	PNZ: ["Petrolina", "PNZ", "BR"],
	POA: ["Porto Alegre", "POA", "BR"],
	BPS: ["Porto Seguro", "BPS", "BR"],
	TVT: ["Porto Seguro - Terravista", "TVT", "BR"],
	TMT: ["Porto Trombetas", "TMT", "BR"],
	PVH: ["Porto Velho", "PVH", "BR"],
	PPB: ["Presidente Prudente", "PPB", "BR"],
	REC: ["Recife", "REC", "BR"],
	REZ: ["Resende", "REZ", "BR"],
	RAO: ["Ribeirão Preto", "RAO", "BR"],
	RBR: ["Rio Branco", "RBR", "BR"],
	RIG: ["Rio Grande", "RIG", "BR"],
	RVD: ["Rio Verde", "RVD", "BR"],
	ROO: ["Rondonópolis", "ROO", "BR"],
	SSA: ["Salvador", "SSA", "BR"],
	IRZ: ["Santa Isabel do Rio Negro", "IRZ", "BR"],
	RIA: ["Santa Maria", "RIA", "BR"],
	SRA: ["Santa Rosa", "SRA", "BR"],
	STM: ["Santarém", "STM", "BR"],
	GEL: ["Santo Angelo", "GEL", "BR"],
	SJL: ["São Gabriel da Cachoeira", "SJL", "BR"],
	JDR: ["São João del-Rei", "JDR", "BR"],
	SJP: ["São Jose do Rio Preto", "SJP", "BR"],
	SJK: ["São Jose dos Campos", "SJK", "BR"],
	SLZ: ["São Luis", "SLZ", "BR"],
	OLC: ["São Paulo de Olivença", "OLC", "BR"],
	OPS: ["Sinop", "OPS", "BR"],
	TBT: ["Tabatinga", "TBT", "BR"],
	TFF: ["Tefé", "TFF", "BR"],
	THE: ["Teresina", "THE", "BR"],
	TJL: ["Três Lagoas", "TJL", "BR"],
	TUR: ["Tucurui", "TUR", "BR"],
	UBA: ["Uberaba", "UBA", "BR"],
	UDI: ["Uberlândia", "UDI", "BR"],
	URG: ["Uruguaiana", "URG", "BR"],
	VAL: ["Valença", "VAL", "BR"],
	VAG: ["Varginha", "VAG", "BR"],
	BVH: ["Vilhena", "BVH", "BR"],
	VIX: ["Vitória", "VIX", "BR"],
	VDC: ["Vitória da Conquista", "VDC", "BR"],
	AAL: ["Aalborg", "AAL", "DK"],
	ABZ: ["Aberdeen", "ABZ", "GB"],
	AUH: ["Abu Dhabi International", "AUH", "AE"],
	AGU: ["Acapulco", "AGU", "MX"],
	DTW: ["Aeroporto de Detroit", "DTW", "US"],
	DWC: ["Aeroporto Internacional Al Maktoum", "DWC", "AE"],
	AGF: ["Agen", "AGF", "FR"],
	AMD: ["Ahmedabad", "AMD", "IN"],
	AJA: ["Ajaccio", "AJA", "FR"],
	CAK: ["Akron/Canton", "CAK", "US"],
	ABY: ["Albany", "ABY", "US"],
	ABR: ["Alberdeen", "ABR", "US"],
	ABQ: ["Albuquerque", "ABQ", "US"],
	HBE: ["Alexandria", "HBE", "EG"],
	AEX: ["Alexandria", "AEX", "US"],
	AHO: ["Alghero", "AHO", "IT"],
	ABE: ["Allentown", "ABE", "US"],
	ALA: ["Almaty", "ALA", "KZ"],
	APN: ["Alpena", "APN", "US"],
	AMA: ["Amarillo", "AMA", "US"],
	ATQ: ["Amritsar", "ATQ", "IN"],
	AMS: ["Amsterdam", "AMS", "NE"],
	ANC: ["Anchorage", "ANC", "US"],
	AOI: ["Ancona", "AOI", "IT"],
	CRK: ["Angeles Mabalacat", "CRK", "PH"],
	ESB: ["Ankara", "ESB", "TR"],
	NCY: ["Annecy", "NCY", "FR"],
	ANF: ["Antofagasta", "ANF", "CL"],
	ANR: ["Antuérpia", "ANR", "BE"],
	ATW: ["Appleton", "ATW", "US"],
	AQP: ["Arequipa", "AQP", "PE"],
	ALG: ["Argel", "ALG", "DZ"],
	ARI: ["Arica", "ARI", "CL"],
	AUA: ["Aruba", "AUA", "AW"],
	AVL: ["Asheville", "AVL", "US"],
	ASU: ["Assunção", "ASU", "PY"],
	TSE: ["Astana", "TSE", "KZ"],
	ATH: ["Athenas", "ATH", "GR"],
	ATL: ["Atlanta", "ATL", "US"],
	AKL: ["Auckland International", "AKL", "NZ"],
	AGS: ["Augusta", "AGS", "US"],
	AUS: ["Austin", "AUS", "US"],
	BGW: ["Bagdá", "BGW", "IQ"],
	BHI: ["Bahia Blanca - Comandante Espora", "BHI", "AR"],
	BAH: ["Bahrein", "BAH", "BH"],
	DPS: ["Bali", "DPS", "ID"],
	BBA: ["Balmaceda", "BBA", "CL"],
	BWI: ["Baltimore", "BWI", "US"],
	BLR: ["Bangalore", "BLR", "IN"],
	BKK: ["Bangkok - Suvarnabhumi Internacional", "BKK", "TH"],
	BGI: ["Barbados", "BGI", "BB"],
	BCN: ["Barcelona", "BCN", "ES"],
	BRI: ["Bari - Palese", "BRI", "IT"],
	BRC: ["Bariloche Internacional", "BRC", "AR"],
	BAQ: ["Barranquilla - Ernesto Cortissoz International", "BAQ", "CL"],
	BTR: ["Baton Rouge", "BTR", "US"],
	BEY: ["Beirute", "BEY", "LB"],
	BHD: ["Belfast", "BHD", "GB"],
	BFS: ["Belfast", "BFS", "GB"],
	BEG: ["Belgrade", "BEG", "RS"],
	BZE: ["Belize", "BZE", "BZ"],
	BJI: ["Bemidji", "BJI", "US"],
	BEN: ["Benghazi", "BEN", "LY"],
	BGO: ["Bergen - Flesland", "BGO", "NO"],
	BRN: ["Berne", "BRN", "CH"],
	BIQ: ["Biarritz", "BIQ", "FR"],
	BIO: ["Bilbao", "BIO", "ES"],
	BIL: ["Billings", "BIL", "US"],
	BLL: ["Bilund", "BLL", "DK"],
	BGM: ["Binghamton", "BGM", "US"],
	BHM: ["Birmingham", "BHM", "UK"],
	BIS: ["Bismarck", "BIS", "US"],
	BMI: ["Bloomington", "BMI", "US"],
	TRI: ["Blountville", "TRI", "US"],
	BOG: ["Bogotá", "BOG", "CO"],
	BOI: ["Boise", "BOI", "US"],
	BLQ: ["Bologna - Guglielmo Marconi", "BLQ", "IT"],
	BOD: ["Bordeaux", "BOD", "FR"],
	BOS: ["Boston Logan Intl", "BOS", "US"],
	BZN: ["Bozeman", "BZN", "US"],
	BRD: ["Brainerd", "BRD", "US"],
	BRE: ["Bremen", "BRE", "DE"],
	BES: ["Brest", "BES", "FR"],
	BDS: ["Brindisi", "BDS", "IT"],
	BNE: ["Brisbane", "BNE", "AU"],
	BRS: ["Bristol", "BRS", "GB"],
	BQK: ["Brunswick", "BQK", "US"],
	BRU: ["Bruxelas", "BRU", "BE"],
	BGA: ["Bucaramanga - Palonegro International", "BGA", "CO"],
	BUH: ["Bucareste", "BUH", "RO"],
	OTP: ["Bucareste - Henri Coanda", "OTP", "RO"],
	BUD: ["Budapeste - Liszt Ferenc International", "BUD", "HU"],
	BUF: ["Buffalo", "BUF", "US"],
	BUR: ["Burbank", "BUR", "US"],
	BTV: ["Burlington", "BTV", "US"],
	BTM: ["Butte", "BTM", "US"],
	CFR: ["Caen", "CFR", "FR"],
	CAG: ["Cagliari", "CAG", "IT"],
	CAI: ["Cairo Internacional", "CAI", "EG"],
	CJC: ["Calama", "CJC", "CL"],
	YYC: ["Calgary", "YYC", "CA"],
	CLO: ["Cali", "CLO", "CO"],
	CLY: ["Calvi", "CLY", "FR"],
	CPE: ["Campeche", "CPE", "MX"],
	CUN: ["Cancún International", "CUN", "MX"],
	CCS: ["Caracas", "CCS", "VE"],
	CWL: ["Cardiff", "CWL", "GB"],
	CTG: ["Cartagena - Rafael Nunez International", "CTG", "CO"],
	CMN: ["Casablanca", "CMN", "MA"],
	CPR: ["Casper", "CPR", "US"],
	DCM: ["Castres", "DCM", "FR"],
	CTC: ["Catamarca", "CTC", "AR"],
	CTA: ["Catania", "CTA", "IT"],
	CDC: ["Cedar City", "CDC", "US"],
	CID: ["Cedar Rapids", "CID", "US"],
	CRW: ["Charleston", "CRW", "US"],
	CHS: ["Charleston", "CHS", "US"],
	CHO: ["Charlottesville ", "CHO", "US"],
	CLT: ["Charlottle", "CLT", "US"],
	CHA: ["Chattanooga", "CHA", "US"],
	CTU: ["Chengdu", "CTU", "CN"],
	MAA: ["Chennai", "MAA", "IN"],
	CIX: ["Chiclayo", "CIX", "PE"],
	CUU: ["Chihuahua", "CUU", "MX"],
	CKG: ["Chongqing", "CKG", "CN"],
	CPT: ["Cidade do Cabo - Cape Town Internacional", "CPT", "ZA"],
	MEX: ["Cidade do México", "MEX", "MX"],
	CVG: ["Cincinatti", "CVG", "US"],
	CME: ["Ciudad del Carmen", "CME", "MX"],
	AGT: ["Ciudad del Leste", "AGT", "PY"],
	CJS: ["Ciudad Juárez", "CJS", "MX"],
	CEN: ["Ciudad Obregon", "CEN", "MX"],
	CGF: ["Cleveland", "CGF", "US"],
	CLE: ["Cleveland Hopkins Intl", "CLE", "US"],
	COK: ["Cochin", "COK", "IN"],
	COD: ["Cody", "COD", "US"],
	CMB: ["Colombo", "CMB", "LK"],
	CGN: ["Colônia", "CGN", "DE"],
	COS: ["Colorado Springs", "COS", "US"],
	COU: ["Columbia", "COU", "US"],
	CAE: ["Columbia", "CAE", "US"],
	CMH: ["Columbus", "CMH", "US"],
	GTR: ["Columbus", "GTR", "US"],
	CSG: ["Columbus", "CSG", "US"],
	CRD: ["Comodoro Rivadavia", "CRD", "AR"],
	CCP: ["Concepción - Carriel Sur", "CCP", "CL"],
	CPH: ["Copenhagen", "CPH", "DK"],
	CPO: ["Copiapó", "CPO", "CL"],
	COR: ["Córdoba", "COR", "AR"],
	CNQ: ["Corrientes", "CNQ", "AR"],
	CZM: ["Cozumel", "CZM", "MX"],
	KRK: ["Cracovia", "KRK", "PL"],
	CRV: ["Crotone", "CRV", "IT"],
	CUC: ["Cúcuta - Camilo Daza", "CUC", "CO"],
	CUL: ["Culiacan", "CUL", "MX"],
	CUR: ["Curaçao", "CUR", "CW"],
	CUZ: ["Cuzco", "CUZ", "PE"],
	DAC: ["Daca", "DAC", "BD"],
	QDF: ["Dallas", "QDF", "US"],
	DAL: ["Dallas", "DAL", "US"],
	DFW: ["Dallas Fort Worth Intl", "DFW", "US"],
	DMM: ["Dammam", "DMM", "SA"],
	DAY: ["Dayton", "DAY", "US"],
	DAB: ["Daytona Beach", "DAB", "US"],
	DEL: ["Delhi - Indira Gandhi Internacional", "DEL", "IN"],
	DEN: ["Denver", "DEN", "US"],
	DSM: ["Des Moines", "DSM", "US"],
	DTT: ["Detroit", "DTT", "US"],
	DVL: ["Devils Lake", "DVL", "US"],
	DOH: ["Doha - Hamad International", "DOH", "QA"],
	DHN: ["Dothan", "DHN", "US"],
	DXB: ["Dubai International", "DXB", "AE"],
	DUB: ["Dublin", "DUB", "IE"],
	DBV: ["Dubrovnik", "DBV", "HR"],
	DLH: ["Duluth", "DLH", "US"],
	DGO: ["Durango", "DGO", "MX"],
	MME: ["Durham", "MME", "GB"],
	DUS: ["Dusseldorf", "DUS", "DE"],
	EGE: ["Eagle County-Vail", "EGE", "US"],
	EDI: ["Edinburgo", "EDI", "GB"],
	YEA: ["Edmonton", "YEA", "CA"],
	YEG: ["Edmonton", "YEG", "CA"],
	EIN: ["Eindhoven", "EIN", "NL"],
	FTE: ["El Calafate", "FTE", "AR"],
	ELP: ["El Paso", "ELP", "US"],
	ESR: ["El Salvador", "ESR", "CL"],
	EKO: ["Elko", "EKO", "US"],
	ELM: ["Elmira/corning", "ELM", "US"],
	XNB: ["Emirados Árabes Unidos", "XNB", "AE"],
	ERI: ["Erie", "ERI", "US"],
	EQS: ["Esquei", "EQS", "AR"],
	EUG: ["Eugene", "EUG", "US"],
	EVV: ["Evansville", "EVV", "US"],
	FAR: ["Fargo", "FAR", "US"],
	FAO: ["Faro", "FAO", "PT"],
	XNA: ["Fayetteville", "XNA", "US"],
	FAY: ["Fayetteville", "FAY", "US"],
	FSC: ["Figari", "FSC", "FR"],
	PHL: ["Filadelfia", "PHL", "US"],
	FNT: ["Flint", "FNT", "US"],
	FLR: ["Florença", "FLR", "IT"],
	FLO: ["Florence", "FLO", "US"],
	FMA: ["Formosa", "FMA", "AR"],
	FOD: ["Fort Dodge", "FOD", "US"],
	FLL: ["Fort Lauderdale-Miami", "FLL", "US"],
	RSW: ["Fort Myers", "RSW", "US"],
	FSM: ["Fort Smith", "FSM", "US"],
	FWA: ["Fort Wayne", "FWA", "US"],
	FRA: ["Frankfurt", "FRA", "DE"],
	FPO: ["Freeport", "FPO", "BS"],
	FAT: ["Fresno", "FAT", "US"],
	FUK: ["Fukuoka", "FUK", "JP"],
	FNC: ["Funchal", "FNC", "PT"],
	GNV: ["Gainesville", "GNV", "US"],
	ELQ: ["Gassim", "ELQ", "SA"],
	GVA: ["Geneva", "GVA", "CH"],
	GOA: ["Genova", "GOA", "IT"],
	GGT: ["Georgetown", "GGT", "BS"],
	GCC: ["Gillete", "GCC", "US"],
	GLA: ["Glasgow Internacional", "GLA", "UK"],
	GOI: ["Goa", "GOI", "IN"],
	GOT: ["Goteborg - Landvetter", "GOT", "SE"],
	LPA: ["Gran Canária", "LPA", "ES"],
	GRX: ["Granada", "GRX", "ES"],
	GFK: ["Grand Forks", "GFK", "US"],
	GJT: ["Grand Junction", "GJT", "US"],
	GRR: ["Grand Rapids", "GRR", "US"],
	GTF: ["Great Falls", "GTF", "US"],
	GRB: ["Green Bay", "GRB", "US"],
	GSO: ["Greensboro", "GSO", "US"],
	GLH: ["Greenville", "GLH", "US"],
	GSP: ["Greenville", "GSP", "US"],
	GDL: ["Guadalajara", "GDL", "MX"],
	CAN: ["Guangzhou", "CAN", "CN"],
	GUA: ["Guatemala", "GUA", "GT"],
	GYE: ["Guayaquil - Jose Joaquin De Olmedo", "GYE", "EC"],
	GPT: ["Gulfport", "GPT", "US"],
	YHZ: ["Halifax", "YHZ", "CA"],
	HAM: ["Hamburgo", "HAM", "DE"],
	HAN: ["Hanoi", "HAN", "VN"],
	HAJ: ["Hanover", "HAJ", "DE"],
	MDT: ["Harrissburg", "MDT", "US"],
	BDL: ["Hartford", "BDL", "US"],
	HAV: ["Havana", "HAV", "CU"],
	HDN: ["Hayden", "HDN", "US"],
	HLN: ["Helena", "HLN", "US"],
	HEL: ["Helsinki Vantaa", "HEL", "FI"],
	HMO: ["Hermosillo", "HMO", "MX"],
	HIB: ["Hibbing", "HIB", "US"],
	HHH: ["Hilton Head Island", "HHH", "US"],
	SGN: ["Ho Chi Minh", "SGN", "VN"],
	HOF: ["Hofuf", "HOF", "SA"],
	HKG: ["Hong Kong", "HKG", "CN"],
	HNL: ["Honolulu", "HNL", "US"],
	HOR: ["Horta", "HOR", "PT"],
	HOU: ["Houston", "HOU", "US"],
	QHO: ["Houston", "QHO", "US"],
	IAH: ["Houston George Bush Intl", "IAH", "US"],
	HUX: ["Huatulco", "HUX", "MX"],
	HUY: ["Humberside", "HUY", "GB"],
	HTS: ["Huntington", "HTS", "US"],
	HSV: ["Huntsville", "HSV", "US"],
	HYD: ["Hyderabad", "HYD", "IN"],
	IBZ: ["Ibiza", "IBZ", "ES"],
	IDA: ["Idaho Falls", "IDA", "US"],
	IGR: ["Iguazu - Cataratas Del Iguazu", "IGR", "AR"],
	IPC: ["Ilha de Páscoa - Mataveri Internacional", "IPC", "CL"],
	IND: ["Indianapolis", "IND", "US"],
	INL: ["International Falls", "INL", "US"],
	IQQ: ["Iquique", "IQQ", "CL"],
	IQT: ["Iquitos", "IQT", "PE"],
	IMT: ["Iron Mountain", "IMT", "US"],
	ISB: ["Islamabad", "ISB", "PK"],
	SAW: ["Istambul", "SAW", "TR"],
	IST: ["Istambul", "IST", "TR"],
	ITH: ["Ithaca", "ITH", "US"],
	ZIH: ["Ixtapa Zihuatanejo", "ZIH", "MX"],
	JAC: ["Jackson", "JAC", "US"],
	JAN: ["Jackson", "JAN", "US"],
	JAX: ["Jacksonville", "JAX", "US"],
	OAJ: ["Jacksonville ", "OAJ", "US"],
	CGK: ["Jakarta", "CGK", "ID"],
	JMS: ["Jamestown", "JMS", "US"],
	JED: ["Jeddah", "JED", "SA"],
	JNB: ["Joanesburgo - O.R. Tambo Internacional", "JNB", "ZA"],
	JUJ: ["Jujuy", "JUJ", "AR"],
	JUL: ["Juliaca", "JUL", "PE"],
	AZO: ["Kalamazoo", "AZO", "US"],
	FCA: ["Kalispell", "FCA", "US"],
	MCI: ["Kansas City", "MCI", "US"],
	KHI: ["Karachi", "KHI", "PK"],
	LIH: ["Kauai Island", "LIH", "US"],
	EYW: ["Key West", "EYW", "US"],
	KBP: ["Kiev - Boryspil International", "KBP", "UA"],
	GRK: ["Killeen", "GRK", "US"],
	KIN: ["Kingston", "KIN", "JM"],
	TYS: ["Knoxville", "TYS", "US"],
	CCU: ["Kolkata", "CCU", "IN"],
	KOA: ["Kona", "KOA", "US"],
	CCJ: ["Kozhikode", "CCJ", "IN"],
	KUL: ["Kuala Lumpur Internacional", "KUL", "MY"],
	KWI: ["Kuwait Internacional", "KWI", "KW"],
	LSE: ["La Crosse", "LSE", "US"],
	LAP: ["La Paz", "LAP", "MX"],
	IRJ: ["La Rioja", "IRJ", "AR"],
	LRH: ["La Rochelle", "LRH", "FR"],
	LSC: ["La Serena", "LSC", "CL"],
	LFT: ["Lafaytte", "LFT", "US"],
	SUF: ["Lamezia", "SUF", "IT"],
	LMP: ["Lampedusa", "LMP", "IT"],
	LAI: ["Lannion", "LAI", "FR"],
	LAN: ["Lansing", "LAN", "US"],
	LHE: ["Laore", "LHE", "PK"],
	LRD: ["Laredo", "LRD", "US"],
	LCA: ["Larnaca", "LCA", "CY"],
	LAS: ["Las Vegas", "LAS", "US"],
	PIB: ["Laurel", "PIB", "US"],
	LEH: ["Le Havre", "LEH", "FR"],
	LBA: ["Leeds", "LBA", "GB"],
	LEJ: ["Leipzig/Halle", "LEJ", "DE"],
	BJX: ["León", "BJX", "MX"],
	LWB: ["Lewisburg", "LWB", "US"],
	LWS: ["Lewiston", "LWS", "US"],
	LEX: ["Lexington", "LEX", "US"],
	LIR: ["Liberia", "LIR", "CR"],
	LIL: ["Lille", "LIL", "FR"],
	LIM: ["Lima", "LIM", "PE"],
	LIG: ["Limoges", "LIG", "FR"],
	LNK: ["Lincoln", "LNK", "US"],
	LPI: ["Linkoping", "LPI", "SE"],
	LIS: ["Lisboa", "LIS", "PT"],
	LIT: ["Little Rock", "LIT", "US"],
	LPL: ["Liverpool", "LPL", "GB"],
	YXU: ["London", "YXU", "CA"],
	LGB: ["Long Beach", "LGB", "US"],
	LRT: ["Lorient", "LRT", "FR"],
	LAX: ["Los Angeles", "LAX", "US"],
	LMM: ["Los Mochis", "LMM", "MX"],
	SDF: ["Louisville", "SDF", "US"],
	LDE: ["Lourdes", "LDE", "FR"],
	LBB: ["Lubbock", "LBB", "US"],
	LUX: ["Luxembourg", "LUX", "LU"],
	LXR: ["Luxor", "LXR", "EG"],
	LYH: ["Lynchburg", "LYH", "US"],
	LYS: ["Lyon - Saint Exupery", "LYS", "FR"],
	MST: ["Maastricht", "MST", "NL"],
	MSN: ["Madison", "MSN", "US"],
	MAD: ["Madri Barajas Intl", "MAD", "ES"],
	AGP: ["Malaga", "AGP", "ES"],
	MAN: ["Manchester", "MAN", "GB"],
	MHT: ["Manchester", "MHT", "US"],
	MNL: ["Manilla", "MNL", "PH"],
	MSE: ["Manston", "MSE", "GB"],
	MDQ: ["Mar del Plata", "MDQ", "AR"],
	MRG: ["Mareeba", "MRG", "AU"],
	MQT: ["Marquette ", "MQT", "US"],
	RAK: ["Marrakech - Menara", "RAK", "MA"],
	MRS: ["Marseille Provence", "MRS", "FR"],
	MCW: ["Mason", "MCW", "US"],
	MAM: ["Matamoros", "MAM", "MX"],
	MZT: ["Mazatlán", "MZT", "MX"],
	MFE: ["Mcallen", "MFE", "US"],
	MDE: ["Medellin", "MDE", "CO"],
	MFR: ["Medford", "MFR", "US"],
	MED: ["Medina", "MED", "SA"],
	MEL: ["Melbourne", "MEL", "AU"],
	MLB: ["Melbourne", "MLB", "US"],
	MEM: ["Memphis", "MEM", "US"],
	MDZ: ["Mendoza", "MDZ", "AR"],
	MID: ["Merida", "MID", "MX"],
	MEI: ["Meridian", "MEI", "US"],
	ETZ: ["Metz/Nancy", "ETZ", "FR"],
	MXL: ["Mexicali", "MXL", "MX"],
	MIA: ["Miami Intl", "MIA", "US"],
	RGN: ["Mianmar", "RGN", "BU"],
	MKE: ["Milwaukee", "MKE", "US"],
	MTT: ["Minatitlan", "MTT", "MX"],
	MSP: ["Minneapolis", "MSP", "US"],
	MOT: ["Minot", "MOT", "US"],
	MSQ: ["Minsk", "MSQ", "BY"],
	MSO: ["Missoula", "MSO", "US"],
	MOB: ["Mobile", "MOB", "US"],
	MLI: ["Moline", "MLI", "US"],
	MLU: ["Monroe", "MLU", "US"],
	MBJ: ["Montego Bay", "MBJ", "JM"],
	MTY: ["Monterrey", "MTY", "MX"],
	MVD: ["Montevideo", "MVD", "UY"],
	MGM: ["Montgomery", "MGM", "US"],
	MPL: ["Montpellier", "MPL", "FR"],
	YMQ: ["Montreal", "YMQ", "CA"],
	YUL: ["Montreal", "YUL", "CA"],
	MTJ: ["Montrose", "MTJ", "US"],
	MLM: ["Morelia", "MLM", "MX"],
	BOM: ["Mumbai Chhatrapati Shivaji", "BOM", "IN"],
	MUC: ["Munique", "MUC", "DE"],
	MSL: ["Muscle Shoals", "MSL", "US"],
	JMK: ["Mykonos", "JMK", "GR"],
	MYR: ["Myrtle Beach", "MYR", "US"],
	NGO: ["Nagoya", "NGO", "JP"],
	NTE: ["Nantes", "NTE", "FR"],
	NAP: ["Nápoles - Naples International", "NAP", "IT"],
	BNA: ["Nashville", "BNA", "US"],
	NAS: ["Nassau", "NAS", "BS"],
	NQN: ["Neuquén", "NQN", "AR"],
	EWN: ["New Bern", "EWN", "US"],
	NCL: ["Newcastle", "NCL", "GB"],
	PHF: ["Newport News", "PHF", "US"],
	NCE: ["Nice - Côte D'Azur", "NCE", "FR"],
	ORF: ["Norfolk", "ORF", "US"],
	NWI: ["Norwich", "NWI", "GB"],
	MSY: ["Nova Orleans", "MSY", "US"],
	NLD: ["Novo Laredo", "NLD", "MX"],
	NUE: ["Nuremberg", "NUE", "DE"],
	OAK: ["Oakland", "OAK", "US"],
	OAX: ["Oaxaca", "OAX", "MX"],
	OMA: ["Omaha", "OMA", "US"],
	ONT: ["Ontario", "ONT", "US"],
	ORN: ["Orã", "ORN", "DZ"],
	MCO: ["Orlando", "MCO", "US"],
	OSA: ["Osaka", "OSA", "JP"],
	KIX: ["Osaka - Kansai Internacional", "KIX", "JP"],
	OSL: ["Oslo", "OSL", "NO"],
	ZOS: ["Osorno", "ZOS", "CL"],
	YOW: ["Ottawa", "YOW", "CA"],
	PMO: ["Palermo", "PMO", "IT"],
	PBI: ["Palm Beach", "PBI", "US"],
	PSP: ["Palm Springs", "PSP", "US"],
	PMI: ["Palma de Mallorca", "PMI", "ES"],
	PNA: ["Pamplona", "PNA", "ES"],
	ECP: ["Panamá", "ECP", "US"],
	PTY: ["Panama City", "PTY", "PA"],
	PRA: ["Paraná", "PRA", "AR"],
	PSC: ["Pasco", "PSC", "US"],
	PUF: ["Pau", "PUF", "FR"],
	PLN: ["Pellston", "PLN", "US"],
	PNS: ["Pensacola", "PNS", "US"],
	PIA: ["Peoria", "PIA", "US"],
	PEK: ["Pequim", "PEK", "CN"],
	PEI: ["Pereira", "PEI", "CO"],
	PGF: ["Perpinhã", "PGF", "FR"],
	PER: ["Perth", "PER", "AU"],
	PSR: ["Pescara", "PSR", "IT"],
	PEW: ["Peshawar", "PEW", "PK"],
	PNH: ["Phnom Penh", "PNH", "KH"],
	PHX: ["Phoenix", "PHX", "US"],
	HKT: ["Phuket", "HKT", "TH"],
	PIR: ["Pierre", "PIR", "US"],
	PSA: ["Pisa", "PSA", "IT"],
	PIT: ["Pittsburgh", "PIT", "US"],
	PIU: ["Piura", "PIU", "PE"],
	PIH: ["Pocatello", "PIH", "US"],
	PDL: ["Ponta Delgada", "PDL", "PT"],
	PWM: ["Portland", "PWM", "US"],
	PDX: ["Portland", "PDX", "US"],
	OPO: ["Porto", "OPO", "PT"],
	PAP: ["Porto Principe", "PAP", "HT"],
	PXO: ["Porto Santo", "PXO", "PT"],
	PSS: ["Posadas", "PSS", "AR"],
	PAZ: ["Poza Rica", "PAZ", "MX"],
	PRG: ["Praga - Ruzyne", "PRG", "CZ"],
	PVD: ["Providence", "PVD", "US"],
	PCL: ["Pucallpa", "PCL", "PE"],
	PEM: ["Puerto Maldonado", "PEM", "PE"],
	PMC: ["Puerto Montt", "PMC", "CL"],
	POP: ["Puerto Plata", "POP", "DM"],
	PVR: ["Puerto Vallarta", "PVR", "MX"],
	PUQ: ["Punta Arenas", "PUQ", "CL"],
	PUJ: ["Punta Cana", "PUJ", "DO"],
	PDP: ["Punta Del Este", "PDP", "UY"],
	YQB: ["Quebec", "YQB", "CA"],
	QRO: ["Queretaro", "QRO", "MX"],
	UIP: ["Quimper", "UIP", "FR"],
	UIO: ["Quito - Mariscal Sucre International", "UIO", "EC"],
	RBA: ["Rabat", "RBA", "MA"],
	RDU: ["Raleigh Durham", "RDU", "US"],
	RAP: ["Rapid City", "RAP", "US"],
	RDM: ["Redmond", "RDM", "US"],
	REG: ["Reggio Calabria", "REG", "IT"],
	YQR: ["Regina", "YQR", "CA"],
	RNS: ["Rennes", "RNS", "FR"],
	RNO: ["Reno", "RNO", "US"],
	RES: ["Resistencia", "RES", "AR"],
	REX: ["Reynosa", "REX", "MX"],
	RHI: ["Rhinelander", "RHI", "US"],
	RUH: ["Riade", "RUH", "SA"],
	RIC: ["Richmond", "RIC", "US"],
	RGL: ["Rio Gallegos", "RGL", "AR"],
	RGA: ["Rio Grande", "RGA", "AR"],
	RHD: ["Rio Hondo", "RHD", "AR"],
	ROA: ["Roanoke", "ROA", "US"],
	RTB: ["Roatan", "RTB", "HN"],
	RST: ["Rochester", "RST", "US"],
	ROC: ["Rochester", "ROC", "US"],
	RKS: ["Rock Springs", "RKS", "US"],
	RDZ: ["Rodez", "RDZ", "FR"],
	FCO: ["Roma - Fiumicino", "FCO", "IT"],
	ROS: ["Rosário", "ROS", "AR"],
	RTM: ["Rotterdam", "RTM", "NL"],
	SMF: ["Sacramento", "SMF", "US"],
	MBS: ["Saginaw", "MBS", "US"],
	QSR: ["Salerno", "QSR", "IT"],
	SLC: ["Salt Lake City", "SLC", "US"],
	SLA: ["Salta", "SLA", "AR"],
	SZG: ["Salzburg - Wolfgang A Mozart", "SZG", "AT"],
	ADZ: ["San Andres Island", "ADZ", "CL"],
	SAT: ["San Antonio", "SAT", "US"],
	MYF: ["San Diego - Montgomery Field", "MYF", "US"],
	SAN: ["San Diego - San Diego Intl", "SAN", "US"],
	SFO: ["San Francisco", "SFO", "US"],
	SJC: ["San Jose", "SJC", "US"],
	SJD: ["San Jose Cabo", "SJD", "MX"],
	UAQ: ["San Juan ", "UAQ", "AR"],
	SJU: ["San Juan - Luis Munoz Marin", "SJU", "PR"],
	LUQ: ["San Luis", "LUQ", "AR"],
	SLP: ["San Luis Potosí", "SLP", "MX"],
	CPC: ["San Martin de los Andes", "CPC", "AR"],
	SAP: ["San Pedro Sula", "SAP", "HN"],
	AFA: ["San Rafael", "AFA", "AR"],
	SNA: ["Santa Ana", "SNA", "US"],
	SNU: ["Santa Clara", "SNU", "CU"],
	VVI: ["Santa Cruz de la Sierra", "VVI", "BO"],
	SFN: ["Santa Fé", "SFN", "AR"],
	RSA: ["Santa Rosa", "RSA", "AR"],
	SDR: ["Santander", "SDR", "ES"],
	SDE: ["Santiado del Estero", "SDE", "AR"],
	SCL: ["Santiago", "SCL", "CL"],
	SCQ: ["Santiago De Compostela", "SCQ", "ES"],
	STI: ["Santiago de los Cabbaleros", "STI", "DM"],
	SDQ: ["Santo Domingo", "SDQ", "DO"],
	SJO: ["São José", "SJO", "CR"],
	LED: ["São Petersburgo Pulkovo", "LED", "RU"],
	SRQ: ["Sarasota / Bradenton", "SRQ", "US"],
	BSL: ["Sasileira/Mulhouse", "BSL", "CH"],
	YXE: ["Saskatoon", "YXE", "CA"],
	CIU: ["Sault Ste Marie", "CIU", "US"],
	SAV: ["Savannah", "SAV", "US"],
	SEA: ["Seattle Tacoma Intl", "SEA", "US"],
	SEL: ["Seul", "SEL", "KR"],
	ICN: ["Seul", "ICN", "KR"],
	SHJ: ["Sharjah", "SHJ", "AE"],
	SYZ: ["Shiraz", "SYZ", "IR"],
	SHV: ["Shreveport", "SHV", "US"],
	SIN: ["Singapura - Changi", "SIN", "SI"],
	SUX: ["Sioux City", "SUX", "US"],
	FSD: ["Sioux Falls", "FSD", "US"],
	SOF: ["Sofia", "SOF", "BG"],
	SBN: ["South Bend", "SBN", "US"],
	SOU: ["Southampton", "SOU", "GB"],
	SPU: ["Split", "SPU", "HR"],
	GEG: ["Spokane", "GEG", "US"],
	SGF: ["Springfield", "SGF", "US"],
	RUN: ["St Denis de La Reunion", "RUN", "FR"],
	STL: ["St Louis", "STL", "US"],
	SGU: ["St. George", "SGU", "US"],
	SCE: ["State College", "SCE", "US"],
	SVG: ["Stavanger - Sola", "SVG", "NO"],
	STR: ["Stuttgart Intl", "STR", "DE"],
	SUN: ["Sun Valley", "SUN", "US"],
	SYD: ["Sydney Kingsford Smith", "SYD", "AU"],
	SYR: ["Syracuse", "SYR", "US"],
	TCQ: ["Tacna", "TCQ", "PE"],
	TIF: ["Taif", "TIF", "SA"],
	TPE: ["Taipei", "TPE", "TW"],
	TLH: ["Tallahasse", "TLH", "US"],
	TPA: ["Tampa", "TPA", "US"],
	TAM: ["Tampico", "TAM", "MX"],
	TNG: ["Tânger", "TNG", "MA"],
	TAP: ["Tapachula", "TAP", "MX"],
	TPP: ["Tarapoto", "TPP", "PE"],
	TGU: ["Tegucigalpa", "TGU", "HN"],
	IKA: ["Tehran", "IKA", "IR"],
	TLV: ["Tel Aviv Yafo - Ben Gurion Internacional", "TLV", "IL"],
	ZCO: ["Temuco", "ZCO", "CL"],
	TFN: ["Tenerife", "TFN", "ES"],
	TER: ["Terceira", "TER", "PT"],
	SKG: ["Tessalônica", "SKG", "GR"],
	TVF: ["Thief River Falls", "TVF", "US"],
	TIJ: ["Tijuana", "TIJ", "MX"],
	TIA: ["Tirana", "TIA", "AL"],
	TOL: ["Toledo", "TOL", "US"],
	YYZ: ["Toronto", "YYZ", "CA"],
	YTO: ["Toronto", "YTO", "CA"],
	TRC: ["Torreon", "TRC", "MX"],
	TLN: ["Toulon/Hyeres", "TLN", "FR"],
	TLS: ["Toulouse - Blagnac", "TLS", "FR"],
	TVC: ["Traverse City", "TVC", "US"],
	REL: ["Trelew", "REL", "AR"],
	TRS: ["Trieste", "TRS", "IT"],
	TIP: ["Tripoli", "TIP", "LY"],
	TRU: ["Trujillo", "TRU", "PE"],
	TUS: ["Tucson", "TUS", "US"],
	TUC: ["Tucumán", "TUC", "AR"],
	TUL: ["Tulsa", "TUL", "US"],
	TUN: ["Tunes", "TUN", "TN"],
	TUP: ["Tupelo", "TUP", "US"],
	TRN: ["Turin - Caselle", "TRN", "IT"],
	TGZ: ["Tuxtla Gutiérrez", "TGZ", "MX"],
	TWF: ["Twin Falls", "TWF", "US"],
	USH: ["Ushuaia", "USH", "AR"],
	ZAL: ["Valdivia", "ZAL", "CL"],
	VLD: ["Valdosta", "VLD", "US"],
	VPS: ["Valparaiso", "VPS", "US"],
	YVR: ["Vancouver", "YVR", "CA"],
	WAW: ["Varsóvia - Warsaw Frederic Chopin", "WAW", "PL"],
	VCE: ["Veneza - Marco Polo", "VCE", "IT"],
	VER: ["Veracruz", "VER", "MX"],
	VRN: ["Verona - Villafranca", "VRN", "IT"],
	VDM: ["Viedma", "VDM", "AR"],
	VIE: ["Vienna Internacional", "VIE", "AT"],
	VSA: ["Villahermosa", "VSA", "MX"],
	YYJ: ["Vitoria - Canada", "YYJ", "CA"],
	IAD: ["Washington - Dulles Intl", "IAD", "US"],
	DCA: ["Washington - Ronald Reagan National", "DCA", "US"],
	WAS: ["Washington DC", "WAS", "US"],
	ALO: ["Waterloo", "ALO", "US"],
	ATY: ["Watertown", "ATY", "US"],
	CWA: ["Wausau", "CWA", "US"],
	HPN: ["Westchester", "HPN", "US"],
	ICT: ["Wichita", "ICT", "US"],
	AVP: ["Wilkes-Barre", "AVP", "US"],
	ILM: ["Wilmington", "ILM", "US"],
	YWG: ["Winnipeg", "YWG", "CA"],
	WUH: ["Wuhan", "WUH", "CN"],
	XMN: ["Xiamen", "XMN", "CN"],
	ZCL: ["Zacatecas", "ZCL", "MX"],
	ZAG: ["Zagreb - Pleso", "ZAG", "HR"],
	PBM: ["Zanderij - Suriname - Johan Adolf Pengel", "PBM", "SR"],
	ZRH: ["Zurique", "ZRH", "CH"],
	ADD: ["Adis Abeba", "ADD", "ET"],
	NBO: ["Nairobi", "NBO", "KE"],
	BMA: ["Estocolmo - Bromma", "BMA", "SE"],
	LPB: ["La Paz", "LPB", ""],
	MGA: ["Nicaragua", "MGA", ""]
};
