import { $, decorateWithError, removeErrorDecoration } from "./common";
import { decorateSearch } from "./airport-replacers";
import nanoajax from "nanoajax";
import { removeActiveField } from "../utils/generalDomUtils";

const AIRPORT_HAS_SAME_CITIES_MESSAGE = "Selecione um destino cuja cidade seja diferente da origem";
const FIELD_REQUIRED = "O preenchimento deste campo é obrigatório";

/**
 * Verifica se stretches possuem cidades iguais
 * @param {HTMLElement} dropDown
 * @param {Object} autocompleteWrapperInstance  classe do tipo AutoCompleteWrapper
 */
export const checkStretches = (outboundInstance, inboundInstance) => {
	if (outboundInstance.getData() == null || outboundInstance.getData().city == "") {
		decorateWithError(outboundInstance.elm.parentElement, FIELD_REQUIRED);
		return;
	}

	if (inboundInstance.getData() == null || inboundInstance.getData().city == "") {
		return;
	}

	if (outboundInstance.getData().city == inboundInstance.getData().city) {
		decorateWithError(inboundInstance.elm.parentElement, AIRPORT_HAS_SAME_CITIES_MESSAGE);
		return;
	}

	removeErrorDecoration(outboundInstance.elm.parentElement);
	removeErrorDecoration(inboundInstance.elm.parentElement);
};

/**
 * Extrai o valor entre parênteses de uma string
 * @param {String} str string contendo iata - ex: Salvador (SSA)
 */
export const getIATA = str => {
	const result = str.match(/\(([^)]+)\)/);
	if (result !== null) return result;
};

/**
 * Helper para função displayName
 * @see displayName
 * @param {String} str string contendo iata - ex: Salvador (SSA)
 */
export const _displayAirportName = (airport, iataRegexp) => {
	return (airport.city + (airport.name ? " - " + airport.name.replace(iataRegexp, "") : ""))
					+ " (" + airport.iata + ")";
};

/**
 * decorador de nomes do aeroporto (de acordo com o resultado da busca na API)
 * @param {Object} airport
 */
export const displayName = (airport, fromLocalStorage, fromGeoLocation) => {
	const iataRegexp = /\([A-Z]{3}\)/;

	if (fromLocalStorage) {
		return airport.name.replace(iataRegexp, "") + " (" + airport.iata + ")";
	}

	if (fromGeoLocation) {
		// TODO
	}

	if (airport.country !== "Brazil" && airport.country !== "BR") {
		return _displayAirportName(airport, iataRegexp) + " - " + airport.country;
	}

	return _displayAirportName(airport, iataRegexp);
};

/**
 * Adapter para uso do nanoajax
 * @param {String} location Url da requisição
 * @param {Object} data dados da requisição
 * @param {function} callback função  que devolve os resultados
 */
export const nanoAjaxAdapter = function(path, data, callback) {
	nanoajax.ajax({ url: path, data }, callback);
};

/****************************************
OBS - Os adapters abaixo não são funcionais, são só um exemplo de como reaproveitar
o código caso queira buscar alguma coisa vinda de um recurso diferente (axios, jquery,
XHR puro ou outras bibliotecas)

const JQueryXHRAdapter = function(location, data = null, callback){
    const setup = {url: location, data}
    $.ajax(setup);
}

const axiosAdapter = function(location, data, callback){
 // ...
}
*/

/**
 * Função do plugin de autocomplete que realiza buscas e sugere termos de acordo com
 * o recurso de busca e termos passados
 *
 * @param {resourceAdapter} searchResource recurso usado para pegar informações
 * relativas ao termo passado
 * @param {String} resourcePath caminho de onde {resourceAdapter} vai extrair informações
 * (geralmente, parte de uma URL)
 * @param {String} searchTerm termo a ser pesquisado
 * @param {function} suggestionsCallback callback do plugin de autoComplete usado para
 * sugerir resultados
 */
export const autoCompleteCallback = function(
	searchResource,
	resourcePath,
	resultCallback,
	searchTerm,
	suggestionsCallback
) {
	const icon = this.elm.parentNode.querySelector("i");

	icon.classList.remove("icon-pin", "form-control-feedback");
	icon.classList.add("loader", "form-control-feedback");

	const suggestionsCb = resourceSuggestionsCallback.bind(null, suggestionsCallback, searchTerm, resultCallback);

	searchResource(
		resourcePath +
			encodeURI(searchTerm),
		null,
		suggestionsCb
	);
};

/**
 * Retorna os dados provenientes da consulta a API
 * * o return desta função tem utilidade apenas como flag de testes unitários
 * @param {function} suggestionsCallback função do autcomplete que renderiza sugestões
 * @param {Integer} responseStatus flag de response status do servidor (200, 404, etc)
 * @param {Object} response resposta da API
 */
export const resourceSuggestionsCallback = function(suggestionsCallback, term, resultCallback, responseStatus, response) {
	if (responseStatus == 200) {
		const parsedData = parseResponse(response);

		resultCallback(term, parsedData.length > 0);

		suggestionsCallback(parsedData);

		return parsedData;
	} else {
		return { error: true };
	}
};

/**
 * Callback da função que retorna aeroportos. realiza parse dos
 * aeroportos para o autoComplete
 * @param {Number} responseStatus retorno do status da requisição
 * @param {String} response String contendo dados da requisição
 */
export const airportsCallback = function(responseStatus, response) {
	if (responseStatus == 200) {
		return parseResponse(response);
	} else {
		return { error: true, message: "Nenhum aeroporto encontrado" };
	}
};

/**
 * Renderiza como deve ser a linha de resultado de busca
 * @param {String} item String contendo linha a ser pesquisada (nome da cidade completo)
 * @param {String} search termo a ser pesquisado dentro do item
 */
export const autoCompleteRenderItem = (item, search) => {
	return (
		`<div class="autocomplete-suggestion ${item.group ? 'autocomplete-suggestion--group' : ''}"
			data-val="${item.result}"
			data-iata="${item.iata}"
			data-city="${item.city}"
			data-country="${item.country}"
			tabindex="-1"
			role="option"
			aria-selected="false"
			aria-label="${item.result}">
			<i class="icon-${item.group ? 'town' : 'plane'}"></i>
			<div class="autocomplete-suggestion__content">
				<span class="autocomplete-suggestion__text text-info">${item.name}</span>
				<span class="autocomplete-suggestion__text">
					${item.distance > 1 && item.destinationPlace
						? `${Math.round(item.distance)} Km de ${item.destinationPlace}`
						: `${item.city}, ${item.country}`}
				</span>
			</div>
			<div class="autocomplete-suggestion__iata">${item.iata}</div>
		</div>`
	);
};

/**
 * converte os resultados num array de termos sugeridos pelo autocomplete
 * @param {String} response string retornada pela API
 */
export const parseResponse = response => {
	try {
		const parsedAirports = JSON.parse(response);
		return parsedAirports.map(airportData => {
			return {
				result: displayName(airportData),
				city: airportData.city,
				iata: airportData.iata,
				name: airportData.name,
				group: airportData.group,
				country: airportData.country,
				distance: airportData.distance,
				destinationPlace: airportData.destinationPlace
			};
		});
	} catch (err) {
		return { error: true, message: err };
	}
};

export const setDataFromRequest = function(autoCompleteInstance, iata, status, response) {
	let airports = parseResponse(response);

	if (Array.isArray(airports)) {
		airports = airports.filter(airport => getIATA(airport.result)[1] === iata)[0];
		airports = {
			city: airports.city,
			iata: airports.iata,
			name: airports.name
		};

		autoCompleteInstance.setData(airports);
		autoCompleteInstance.decorateField(autoCompleteInstance.elm, autoCompleteInstance.airportData);
	}
};

export const setDataFromStorage = (autoCompleteInstance, storageAirportData) => {
	autoCompleteInstance.setData(storageAirportData);
	autoCompleteInstance.decorateField(
		autoCompleteInstance.elm,
		autoCompleteInstance.airportData,
		true
	);
};

const redirectToNextElement = currentElement => {
	if (currentElement == "search_form_from") {
		setTimeout(() => $("#search_form_to").focus());
		removeActiveField();
	}

	if (currentElement == "search_form_to") {
		setTimeout(() => $("#search_form_outbound_date").focus());
		removeActiveField();
	}
};

export const autoCompleteSelectCallback = function(autoCompleteInstance, event, term, item) {
	if (item.getAttribute("data-city")) {
		autoCompleteInstance.setData({
			city: item.getAttribute("data-city"),
			iata: item.getAttribute("data-iata"),
			name: item.getAttribute("data-val")
		});

		removeErrorDecoration(autoCompleteInstance.elm.parentElement);
	}

	event.preventDefault();
};
