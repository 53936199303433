import nanoajax from "nanoajax";
import Swiper from "swiper";
import PubSub from "pubsub-js";
import "../../shared/pubsub-triggers";
import moment from "moment";
import {
	BFF_API
} from "../../shared/constants";
import {
	formatCurrency,
	getNearbyLocation
} from "../../shared/utils";

//muda o path de acordo com o final (se tiver porta é endereço local(dev), senão, é endereço de build)
const path =
	window.assets_base_url.match(":8181") !== null ?
	window.assets_base_url + "/src" :
	window.assets_base_url + "";

const htmlData = `<section class="testimonials" data-section="testimonials">
    <div class="container-fluid">
		<h3>As pessoas merecem viajar mais </h3>
		<h4>Mais de 800 mil pessoas já viajaram com a gente. Falta você.</h4>
        <div class="row">
            <div id="swiper-testimonials" class="col-xs-16">
                <div class="swiper-container">
                    <div class="swiper-wrapper">                        
                        <div class="swiper-slide">
                            <div class="item-image">
                                <img class="img-responsive" src="${path}/img/home/depositions/paloma-faria.jpg" alt="">
                                <big>“</big>
                            </div>
                            <div class="item-content">
                                <p>Quero de coração agradecer. Foi uma primeira viagem de avião e a sensação é única. Hoje, estou aqui em Porto Alegre com meu grande amor. Sem vocês seria impossível realizar esse sonho tão rápido. Será sempre minha empresa favorita. Vocês são maravilhosos.<big>”</big></p>
                                <div class="signature">
                                    <hr>
                                    <span class="item-content-name">Paloma Faria</span>
                                    <span class="item-content-city">VIAJOU DE AVIÃO PELA PRIMEIRA VEZ</span>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="item-image">
                                <img class="img-responsive" src="${path}/img/home/depositions/aldney-sousa.jpg" alt="">
                                <big>“</big>
                            </div>
                            <div class="item-content">
                                <p>Estou muito satisfeito e minha mãe vai ficar muito feliz. Ela é velhinha e tem deficiência auditiva, nossa casa não tem muro, nem portão e, agora, vou usar a grana pra terminar o muro e colocar um portão. Parabéns. Continuem assim.<big>”</big></p>
                                <div class="signature">
                                    <hr>
                                    <span class="item-content-name">Aldney Sousa</span>
                                    <span class="item-content-city">CONSEGUIU REFORMAR A CASA DA MÃE</span>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="item-image">
                                <img class="img-responsive" src="${path}/img/home/depositions/leandro-ribeiro.jpg" alt="">
                                <big>“</big>
                            </div>
                            <div class="item-content">
                                <p>Tem muita empresa (qualquer área) que deveria receber uma aula de organização de informações e suporte pós-compra. Obrigado a todas as pessoas que trabalham na MaxMilhas e contribuíram com esta viagem. Nos vemos na próxima.<big>”</big></p>
                                <div class="signature">
                                    <hr>
                                    <span class="item-content-name">Leandro Ribeiro</span>
                                    <span class="item-content-city">VIAJOU PELA MAXMILHAS</span>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="item-image">
                                <img class="img-responsive" src="${path}/img/home/depositions/laerte-fernandes.jpg" alt="">
                                <big>“</big>
                            </div>
                            <div class="item-content">
                                <p>Sinceramente não me recordo de haver feito negócio com uma empresa tão idônea quanto essa. Estou feliz em saber que temos empresas sérias em nosso país. Já estou fazendo propaganda de vocês. Muito obrigado.<big>”</big></p>
                                <div class="signature">
                                    <hr>
                                    <span class="item-content-name">Laerte Fernandes</span>
                                    <span class="item-content-city">PRIMEIRA VIAGEM INTERNACIONAL</span>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="item-image">
                                <img class="img-responsive" src="${path}/img/home/depositions/fabio-wilkens.jpg" alt="">
                                <big>“</big>
                            </div>
                            <div class="item-content">
                                <p>Procurei algum site para vender minhas milhas que estavam vencendo e acabei encontrando a MaxMilhas. Fiz meu cadastro e logo efetuaram minha venda e creditaram em minha conta. Ótimo atendimento e seguro. Estou indicando para vários amigos!!<big>”</big></p>
                                <div class="signature">
                                    <hr>
                                    <span class="item-content-name">Fábio Wilkens</span>
                                    <span class="item-content-city">VENDEU MILHAS DA AVIANCA</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
    </div>
</section>
    
<section class="press" data-section="press">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-16">
                <div class="press-box box-default">
                    <h3>A MaxMilhas é confiável? </h3>
                    <h4>Estamos na mídia e na preferência de quem gosta de economizar.</h4>
                    <div id="swiper-press">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a href="https://endeavor.org.br/todo-negocio-comeca-de-uma-inquietacao-como-a-maxmilhas-mudou-o-mercado-de-milhas-no-pais/" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-endeavor.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>Todo negócio começa de uma inquietação: como a MaxMilhas mudou o mercado de milhas no país.</p>
                                            <span>17 de Maio de 2017</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a href="http://revistapegn.globo.com/Startups/noticia/2017/06/4-dicas-para-criar-uma-startup-de-sucesso-com-recursos-proprios.html" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-pegn.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>Sentir a dor das pessoas, perceber as necessidades delas, é um bom começo para um negócio de sucesso</p>
                                            <span>12 de Junho de 2017</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a href="http://exame.abril.com.br/pme/como-este-negocio-mudou-o-mercado-de-milhas-no-pais/" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-exame.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>Todos nós merecemos férias. Mas os custos de viajar de avião são tão proibitivos que, enquanto um americano viaja, em média duas vezes por ano...</p>
                                            <span>3 de Junho de 2017</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a href="http://economia.estadao.com.br/noticias/geral,consumidor-pode-transformar-milhas-aereas-em-dinheiro,10000091041" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-estadao.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>Consumidor pode transformar milhas aéreas em dinheiro.</p>
                                            <span>28 de Novembro de 2016</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a href="https://conteudo.startse.com.br/mercado/felipe/como-a-pao-durice-de-um-empreendedor-o-fez-criar-uma-grande-startup/" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-startse.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>Com quase quatro anos de mercado, a MaxMilhas cresce exponencialmente sem ter recebido investimento externo</p>
                                            <span>11 de Novembro de 2016</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a href="http://zh.clicrbs.com.br/rs/noticias/economia/noticia/2014/01/negocios-com-milhas-aereas-decolam-na-internet-4399110.html#showNoticia=QWVnRE8haEI3NDMzNjg4Mzg1OTU4NDQ1MDU2OCNsNTg0MjgxMDUwOTMyNzgzMTM1Mnw4NzQ3OTkzODQ5Njk2NDQzNDMyOTZmeDcmQFk9SyZybDxuQUVUPkU=" target="_blank">
                                        <div class="item-image">
                                            <img class="img-responsive" src="${path}/img/home/press/logo-zh.png" alt="">
                                        </div>
                                        <div class="item-content">
                                            <p>A gaúcha de 28 anos pagou metade do preço normal pelas passagens aéreas em um site que ajuda a fazer negócios com milhas. </p>
                                            <span>25 de Janeiro de 2014</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>

                            <div class="swiper-button-prev">
                                <i class="icon-arrow-left"></i>
                            </div>
                            <div class="swiper-button-next">
                                <i class="icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>`;

export const initSwiper = () => {
	const swipersContainer = document.querySelector("#swipers-container");
	swipersContainer.innerHTML = htmlData;

	var swiperPress = new Swiper("#swiper-press .swiper-container", {
		pagination: {
			el: "#swiper-press .swiper-pagination",
			type: "bullets",
			clickable: true
		},
		simulateTouch: true,
		loop: false,
		slidesPerView: 2,
		spaceBetween: 50,
		navigation: {
			nextEl: "#swiper-press .swiper-button-next",
			prevEl: "#swiper-press .swiper-button-prev",
		},
		breakpoints: {
			// when window width is <= 1024px
			1024: {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 15,
				autoplay: 8000
			}
		},
		onSlideChangeStart: function (swiper) {
			PubSub.publish("dataLayer", {
				event: "gaEvent",
				gaEventCategory: "Página Inicial",
				gaEventAction: "Demais elementos",
				gaEventLabel: "Rolagem de imprensa"
			});
		}
	});

	// Home - Testimonials swiper
	var swiperTestimonials = new Swiper("#swiper-testimonials .swiper-container", {
		pagination: {
			el: "#swiper-testimonials .swiper-pagination",
			type: "bullets",
			clickable: true
		},
		simulateTouch: true,
		loop: false,
		slidesPerView: 4,
		spaceBetween: 24,
		allowSlidePrev: true,
		allowSlideNext: true,
		breakpoints: {
			// when window width is <= 480px
			480: {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 12
			},
			// when window width is <= 991px
			1115: {
				loop: true,
				slidesPerView: 2,
				spaceBetween: 24
			}
		},
		onSlideChangeStart: function (swiper) {
			PubSub.publish("dataLayer", {
				event: "gaEvent",
				gaEventCategory: "Página Inicial",
				gaEventAction: "Demais elementos",
				gaEventLabel: "Rolagem de depoimentos"
			});
		}
	});
};
export const initSwiperAbout = () => {
	// Home - About swiper
	var swiperAbout = new Swiper("#swiper-about .swiper-container", {
		pagination: {
			el: null,
			clickable: true
		},
		simulateTouch: false,
		loop: false,
		slidesPerView: 3,
		slidesPerGroup: 3,
		slidesPerColumn: 1,
		breakpoints: {
			768: {
				pagination: {
					el: "#swiper-about .swiper-pagination",
				},
				simulateTouch: true,
				slidesPerView: 1,
				slidesPerGroup: 1,
				slidesPerColumn: 1,
				loop: true
			}
		}
	});
};

export const getSwiperShowcase = () => {
	const showcase = document.querySelector("#showcase");
	const showcaseCity = document.querySelector("#showcase_city");
	const showcaseSwiper = document.querySelector("#swiper-showcase");
	const showcaseLazy = document.querySelector("#swiper_lazyload");

	getNearbyLocation().then(airports => {
		if (Array.isArray(airports) && airports.length > 0) {
			let airport = airports.find(air => air.group !== undefined);

			if (airport === undefined) {
				airport = {
					city: "São Paulo",
					iata: "SAO",
					group: "SAO"
				};
			}

			const airportData = {
				city: airport.city,
				iata: airport.iata,
				group: airport.group
			};

			showcaseCity.innerHTML = airportData.city;

			

			nanoajax.ajax({
					url: `${BFF_API}/best-prices/flights?from=${airportData.group}&size=14`,
					method: "GET"
				},
				function (code, responseText, request) {
					if (code !== 200) {
						showcase.classList.add("hide");

						PubSub.publish("dataLayer", {
							event: "gaEvent",
							gaEventCategory: "Vitrine de recomendação",
							gaEventAction: "Erro",
							gaEventLabel: responseText
						});
					} else {
						const result = JSON.parse(responseText);

						showcaseLazy.classList.add("hide");

						const flight = result
							.map(bestFlight => {
								const {
									url,
									best_price_mm_w_taxes,
									destination_code
								} = bestFlight;

								const date = url.split('/')[7];
								const urlAction = url.split('https://www.maxmilhas.com.br')[1];
								const to = url.split('/')[6];
								const from = url.split('/')[6];

								const destination = bestFlight.destination ? bestFlight.destination : destination_code;
								const formattedDate = moment(date).format("DD/MM");
								const formattedMoney = formatCurrency(best_price_mm_w_taxes);

								return `
									<div class="swiper-slide">
										<a data-action="best-price-window" href="${urlAction}" class="card-infogreen card-infogreen-image">
											<div class="card-infogreen-image--background" aria-describedby="cidade do ${destination}" style="background-image: url(https://s3.amazonaws.com/maxmilhas/airports/600x315/${
												to
											}.jpg)"></div>
											<div class="card-infogreen-image--info">
												<h4>Voe em <br><big>${formattedDate}</big></h4>
												<h5>${destination}</h5>
											</div>
											<h6 class="card-infogreen-image--price box-last">A partir de <span>${formattedMoney}</span></h6>
										</a>
									</div>`;
							})
							.join("");

						showcaseSwiper.innerHTML += `<div class="swiper-container">
												<div class="swiper-wrapper">
													${flight}
												</div>
												<div class="swiper-pagination"></div>
											</div>`;

						PubSub.publish("dataLayer", {
							event: "gaEvent",
							gaEventCategory: event.category,
							gaEventAction: event.action
						});

						PubSub.publish("dataLayer", {
							event: "vitrine-carregada"
						});

						initSwiperShowcase(result.length);
					}
				}
			);
		}
	});
};

const initSwiperShowcase = totalItems => {
	document.querySelector("#showcase").classList.remove("hide");

	new Swiper("#swiper-showcase .swiper-container", {
		pagination: {
			el: "#swiper-showcase .swiper-pagination",
			clickable: true
		},
		slidesPerView: Math.min(totalItems, 5),
		slidesPerGroup: Math.min(totalItems, 5),
		spaceBetween: 30,
		loop: false,
		breakpoints: {
			667: {
				slidesPerView: 1,
				slidesPerGroup: 1
			},
			768: {
				slidesPerView: 2,
				slidesPerGroup: 2
			},
			1024: {
				slidesPerView: Math.min(totalItems, 3),
				slidesPerGroup: Math.min(totalItems, 3)
			},
			1440: {
				slidesPerView: Math.min(totalItems, 4),
				slidesPerGroup: Math.min(totalItems, 4)
			}
		}
	});
};