import nanoajax from "nanoajax";
import slugify from "slugify";
import Swiper from "swiper";
import lozad from 'lozad'
import Trends from "../../components/seo/Trends";
import { destiny } from "../../shared/destinyTexts";
import { fallbackBestPrices } from "../../shared/fallbackBestPrices";
import { PRICES_API, BFF_API , NEARBY_PLACES_API } from "../../shared/constants";
import { airports } from "../../shared/airports";
import { stretchFrom } from "../../searchform";
import { createComponentsFriday } from "./promocionais/friday";
import { formatCurrency, shuffleArray } from "../../shared/utils";
import { initSwiperAbout } from "../partials/HomeSwipers";

const observer = lozad();
observer.observe();

const state = {
	fetchingResourceFrom: null
};

if (typeof generateStylesheet === "function") {
	generateStylesheet("home");
}

createComponentsFriday();

const newsletterInput = document.getElementById("newsletter-email");
const formNewsletter = document.getElementById("formNewsletter");

try {
	initSwiperAbout();
} catch (e) {}

if (formNewsletter) {
	formNewsletter.onsubmit = event => {
		event.preventDefault();
		const successWrapper = document.querySelector(".validate-success-msg");
		const validateError = document.querySelector(".validate-error-msg").parentElement;

		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		const paintError = errorName => {
			validateError.className = "col-xs-16 no-padding";
			validateError.innerHTML = `
                        <div class="alert alert-danger validate-error-msg">
                            <div class="alert--icon">
                                <i class="icon-close"></i>
                            </div>
                            <div class="alert--message">
                                <p>${errorName}</p>
                            </div>
                        </div>`;
		};

		successWrapper.className = successWrapper.className + " hidden";
		validateError.className = validateError.className + " hidden";

		if (re.test(newsletterInput.value.toLowerCase())) {
			extractValues(formNewsletter.querySelectorAll("input, button")).map(item => {
				if (typeof item == "object") item.disabled = true;
			});

			nanoajax.ajax(
				{
					url: "/cadastro-email-marketing",
					method: "POST",
					body: JSON.stringify({ email: newsletterInput.value })
				},
				function(code, responseText, request) {
					console.log(arguments);
					extractValues(formNewsletter.querySelectorAll("input, button")).map(item => {
						if (typeof item == "object") item.disabled = false;
					});

					PubSub.publish("dataLayer", {
						event: "ditoIdentifyEmail",
						ditoIdentify: {
							email: newsletterInput.value
						}
					});

					let cryptoEmail = md5(newsletterInput.value);

					newsletterInput.value = "";

					console.log("code: ", code, code === 400);

					if (code === 201) {
						successWrapper.className = "alert alert-success validate-success-msg";

						PubSub.publish("dataLayer", {
							event: "gaEvent",
							userId: cryptoEmail,
							gaEventCategory: "Identificação de e-mail",
							gaEventAction: "Newsletter",
							gaEventLabel: "home",
							gaEventValue: ""
						});
					}

					if (code === 400) {
						paintError(JSON.parse(responseText)[0].message);
					}

					if (code === 409) {
						paintError("Este email já está cadastrado");
					}

					if (code === 500) {
						paintError("Não foi possível enviar");
					}
				}
			);
		} else {
			paintError("Digite um email válido");
		}
	};
}

export const createComponentsBuscaPassagensAereas = () => {
	if (document.body.className.match(".busca-passagens-aereas")) {
		const urlBuscaPassagens = BFF_API;

		const buscaPassagensDestinyContent = document.querySelector("#swiper-destination .swiper-wrapper");
		const buscaPassagensDestinyContentNew = document.querySelector("#swiper-destination-new .swiper-wrapper");

		const destinationInternationalItems = document.querySelectorAll("#swiper-destination-international .card-largetitle");

		const ciasSliderNode = document.getElementById("cias-slider");

		if (destinationInternationalItems.length) {
			const destinationInternational = () => {
				const destinationInternationalsParent = document.querySelector(
					"#swiper-destination-international"
				).parentElement.parentElement;

				destinationInternationalsParent.classList.remove("hide");

				const swiperDestinationInternational = new Swiper(
					"#swiper-destination-international .swiper-container",
					{
						pagination: { 
							el: "#swiper-destination-international .swiper-pagination",
							type: "bullets",
							clickable: true,
						},
						simulateTouch: false,
						slidesPerView: 3,
						slidesPerGroup: 3,
						slidesPerColumn: 1,
						spaceBetween: 30,
						navigation: {
							nextEl: "#swiper-destination-international .swiper-button-next",
							prevEl: "#swiper-destination-international .swiper-button-prev",
						},
						breakpoints: {
							768: {
								simulateTouch: true,
								slidesPerView: 1,
								slidesPerGroup: 1,
								slidesPerColumn: 1
							}
						}
					}
				);
			};

			window.destinationInternational = destinationInternational;
			destinationInternational();
		}

		if (ciasSliderNode) {
			new Swiper(ciasSliderNode, {
				slidesPerView: 4,
				slidesPerGroup: 4,
				spaceBetween: 30,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				pagination: { 
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true,
				},
				paginationClickable: true,
				breakpoints: {
					1919: {
						slidesPerGroup: 4,
						slidesPerView: 4
					},
					1199: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					991: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					767: {
						slidesPerGroup: 1,
						slidesPerView: 1
					}
				}
			});
		}

		const swiperMaisBuscados = document.querySelector("#swiper-mais-buscados .swiper-container");

		if (swiperMaisBuscados) {
			new Swiper(swiperMaisBuscados, {
				pagination: {
					el: "#swiper-mais-buscados .swiper-pagination",
					type: "bullets",
					clickable: true
				},
				slidesPerView: 6,
				slidesPerGroup: 6,
				spaceBetween: 30,
				navigation: {
					nextEl: "#swiper-mais-buscados .swiper-button-next",
					prevEl: "#swiper-mais-buscados .swiper-button-prev",
				},
				breakpoints: {
					1919: {
						slidesPerGroup: 4,
						slidesPerView: 4
					},
					1199: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					991: {
						slidesPerGroup: 3,
						slidesPerView: 3
					},
					767: {
						slidesPerGroup: 1,
						slidesPerView: 1
					}
				}
			});
		}
	}
};

createComponentsBuscaPassagensAereas();

const swiperDestination = new Swiper(
	"#swiper-destination .swiper-container",
	{
		pagination: {
			el: "#swiper-destination .swiper-pagination",
			type: "bullets",
			clickable: true
		},
		simulateTouch: false,
		loop: false,
		paginationClickable: true,
		slidesPerView: 2,
		slidesPerGroup: 2,
		slidesPerColumn: 2,
		spaceBetween: 30,
		navigation: {
			nextEl: "#swiper-destination .swiper-button-next",
			prevEl: "#swiper-destination .swiper-button-prev",
		},
		breakpoints: {
			768: {
				simulateTouch: true,
				slidesPerView: 1,
				slidesPerGroup: 1,
				slidesPerColumn: 1,
				loop: true
			}
		}
	}
);

const destinationsOneLine = () => {
	const swiperDestinationContainer = document.querySelector("#swiper-destination .swiper-container");
	const swiperDestination = document.querySelector("#swiper-destination");
	const newSwiperDestination = document.querySelector("#swiper-destination-new");

	newSwiperDestination.classList.remove("hide");
	swiperDestination.classList.add("hide");
	swiperDestinationContainer.swiper.destroy();
	swiperDestinationContainer.swiper = undefined;

	const newSwiper = new Swiper(
		"#swiper-destination-new .swiper-container",
		{
			pagination: {
				el: "#swiper-destination-new .swiper-pagination",
				type: "bullets",
				clickable: true
			},
			simulateTouch: false,
			loop: false,
			paginationClickable: true,
			slidesPerView: 3,
			slidesPerGroup: 3,
			slidesPerColumn: 1,
			spaceBetween: 30,
			navigation: {
				nextEl: "#swiper-destination-new .swiper-button-next",
				prevEl: "#swiper-destination-new .swiper-button-prev",
			},
			breakpoints: {
				768: {
					simulateTouch: true,
					slidesPerView: 1,
					slidesPerGroup: 1,
					slidesPerColumn: 1,
					loop: true,
				}
			}
		}
	);
};

const aproveiteTrends = () => {
	const oldAproveite = document.querySelector(".aproveite-old");
	const newAproveite = document.querySelector(".aproveite-new");
	const newAproveiteTitle = document.querySelector(".aproveite-new-title");
	const swiperAproveiteNew = document.querySelector("#swiper-aproveite-new .swiper-container");

	newAproveiteTitle.classList.remove("hide");
	newAproveite.classList.remove("hide");
	oldAproveite.classList.add("hide");

	if (swiperAproveiteNew) {
		new Swiper(swiperAproveiteNew, {
			pagination: {
				el: "#swiper-aproveite-new .swiper-pagination",
				type: "bullets",
				clickable: true
			},
			slidesPerView: 6,
			slidesPerGroup: 6,
			spaceBetween: 30,
			navigation: {
				nextEl: "#swiper-aproveite-new .swiper-button-next",
				prevEl: "#swiper-aproveite-new .swiper-button-prev",
			},
			breakpoints: {
				1919: {
					slidesPerGroup: 4,
					slidesPerView: 4
				},
				1199: {
					slidesPerGroup: 3,
					slidesPerView: 3
				},
				991: {
					slidesPerGroup: 3,
					slidesPerView: 3
				},
				767: {
					slidesPerGroup: 1,
					slidesPerView: 1
				}
			}
		});
	}
}

const newComponents = () => {
	destinationsOneLine();
	aproveiteTrends();
};

window.aproveiteTrends = aproveiteTrends;
window.destinationsOneLine = destinationsOneLine;
window.newComponents = newComponents;

window.addEventListener("load", function() {
	PubSub.publish("dataLayer", {
		event: "criteoPush",
		criteoObjectsToPush: [{ event: "viewHome" }, { setEmail: "" }]
	});

	if (document.body.classList.contains("busca-passagens-aereas")) {
		PubSub.publish("dataLayer", {
			event: "ditoTrack",
			ditoTrack: {
				action: "acessou-busca-passagens-aereas"
			}
		});
	}
});