import nanoajax from "nanoajax";
import Swiper from "swiper";
import moment from "moment";
import { getItemsOnBlog } from "../../../shared/utils";
import { smoothScroll } from "../../../shared/smoothScroll";

export const createComponentsFriday = () => {
	const limitPostsBlog = 4;
	let jsonFriday = null;

	if (document.body.className.match(".holidays.friday-day")) {
		const citiesSwiper = document.querySelector("#swiper-friday");
		const couponFriday = document.querySelector("#coupon_wrapper .coupon-friday");
		const couponCode = document.getElementsByClassName("coupon-code");
		const textAbout = document.getElementById("text_about");
		let windowWidth = window.innerWidth;

		nanoajax.ajax(
			{
				url: `https://s3.amazonaws.com/maxmilhas/sexta-e-dia/friday.json`,
				method: "GET"
			},
			function(code, responseText, request) {
				jsonFriday = JSON.parse(responseText);

				const {
					cities,
					coupon,
					init_date,
					single_banner,
					single_banner_text_small,
					single_banner_text_large,
					single_banner_option_one
				} = jsonFriday;

				const initDate = moment(init_date).format("DD/MM/YYYY");
				const today = moment(new Date()).format("DD/MM/YYYY");

				document.querySelector(".friday-day .loading").remove();

				if (initDate === today) {
					couponFriday.classList.remove("hide");
					citiesSwiper.classList.remove("hide");

					if (windowWidth <= 768) {
						textAbout.textContent =
							"Sexta é dia de economizar! Escolhemos 3 destinos com 10% de desconto e os cupons podem ser usados só hoje, sexta-feira, até 23:59. Partiu?";
						if (jsonFriday.single_banner) {
							textAbout.textContent = jsonFriday.single_banner_text_small;
						}
					} else {
						textAbout.textContent =
							"Sexta é dia de economizar! A cada sexta-feira, escolhemos a dedo três destinos para você conhecer com 10% de desconto. Conheça as cidades da vez e aproveite. Ah, os cupons podem ser usados na  sexta-feira, até 23:59. Partiu? ;) ";
						if (jsonFriday.single_banner) {
							textAbout.textContent = jsonFriday.single_banner_text_large;
						}
					}

					if (jsonFriday.single_banner) {
						try {
							document.evaluate(
								'//*[@id="body"]/div[5]/div[1]/section[2]/div/div/div/div/div/div[1]/div[1]/p',
								document,
								null,
								XPathResult.FIRST_ORDERED_NODE_TYPE,
								null
							).singleNodeValue.innerHTML =
								jsonFriday.single_banner_option_one;
						} catch (e) {
							// do nothing
						}
					}

					for (var i = 0; i < couponCode.length; i += 1) {
						couponCode[i].innerHTML = coupon;
					}

					if (jsonFriday.single_banner) {
						citiesSwiper.innerHTML += `<center><img src="${cities[0].image}" alt=""></center>`;
					} else {
						const cidades = cities
							.map(city => {
								return `<div class="swiper-slide">
                                    <a class="card-largetitle anchor-animation" href="#search-form-section">
                                        <div class="card-largetitle-item salvador-card">
                                            <div class="card-largetitle-item--title">
                                                <img src="${city.image}" alt="">
                                            </div>
                                            <div class="card-largetitle-item--info">
                                                <div class="card-largetitle-item--info-infos">
                                                    <h4>${city.city}</h4>
                                                    <p class="card-largetitle-item--info-text">${
																											city.description
																										}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`;
							})
							.join("");

						citiesSwiper.innerHTML += `<div class="swiper-container">
                                                <div class="swiper-wrapper">
                                                    ${cidades}
                                                </div>
                                                <div class="swiper-pagination"></div>
                                               </div>`;
					}

					let targets = document.querySelectorAll(".anchor-animation"),
						target = null;
					for (target in targets) {
						if (typeof targets[target] === "object") {
							targets[target].onclick = smoothScroll;
						}
					}

					var swiperFriday = new Swiper("#swiper-friday .swiper-container", {
						pagination: false,
						simulateTouch: false,
						loop: false,
						paginationClickable: true,
						slidesPerView: 3,
						slidesPerGroup: 3,
						slidesPerColumn: 1,
						spaceBetween: 30,
						breakpoints: {
							768: {
								pagination: "#swiper-friday .swiper-pagination",
								simulateTouch: true,
								slidesPerView: 1,
								slidesPerGroup: 1,
								slidesPerColumn: 1,
								loop: true
							},
							480: {
								noSwiping: true,
								direction: 'vertical',
								simulateTouch: false,
								allowTouchMove: false
							},   
						}
					});

					if (windowWidth <= 480) {
						swiperFriday.destroy();
					}
				}
			}
		);

		nanoajax.ajax(
			{
				url: `https://www.maxmilhas.com.br/blog/?feed=rss2&s=`,
				method: "GET"
			},
			function(code, responseText, request) {
				let data;

				document.querySelector(".blog-friday .loading").remove();
				const blogDestinosContent = document.querySelector(
					".blog-friday .swiper-container .swiper-wrapper"
				);

				if (code === 200 && responseText) {
					if (window.DOMParser) {
						const parser = new DOMParser();
						data = parser.parseFromString(responseText, "text/xml");
					} else {
						// Internet Explorer
						data = new ActiveXObject("Microsoft.XMLDOM");
						data.async = false;
						data.loadXML(responseText);
					}

					const channelXML = data.getElementsByTagName("channel")[0];

					const { length } = getItemsOnBlog(channelXML.getElementsByTagName("item"));

					const createItemsOnScreen = channelXML => {
						for (var i = 0; i < limitPostsBlog; i++) {
							const item = channelXML.getElementsByTagName("item")[i];

							if (!item) {
								return false;
							}

							const title = item.getElementsByTagName("title")[0].textContent;
							const link = item.getElementsByTagName("link")[0].textContent;
							const image = item.getElementsByTagName("image")[0]
								? item.getElementsByTagName("image")[0].textContent
								: "<img width='355' height='237' src='../../../../img/defaults/img-default-770x305.jpg' class='attachment-image-372 size-image-372 wp-post-image' />";
							const description = item.getElementsByTagName("description")[0].textContent;

							let columns = "";

							if (length == 3) {
								columns = " col-md-4";
							} else if (length == 2 && i == 0) {
								columns = " col-md-4 col-md-offset-2";
							} else if (length == 2 && i != 0) {
								columns = " col-md-4";
							} else {
								columns = " col-md-3";
							}

							blogDestinosContent.innerHTML += `<div class="swiper-slide">
                                    <a href="${link.replace(/https:\/\/maxmilhas\.wpengine\.com/, 'https://www.maxmilhas.com.br/blog')}" class="card-largetitle blog" target="_blank">
                                        <div class="card-largetitle-item">
                                            <div class="card-largetitle-item--title">
                                                ${image}
                                            </div>
                                            <div class="card-largetitle-item--info">
                                                <div class="card-largetitle-item--info-infos">
                                                    <h4>${title}</h4>
                                                    <p class="card-largetitle-item--info-text">
                                                    ${description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>`;
						}
					};

					if (length < 2) {
						nanoajax.ajax(
							{
								url: `https://www.maxmilhas.com.br/blog/?feed`,
								method: "GET"
							},
							function(code, responseText, request) {
								if (window.DOMParser) {
									const parser = new DOMParser();
									data = parser.parseFromString(responseText, "text/xml");
								} else {
									// Internet Explorer
									data = new ActiveXObject("Microsoft.XMLDOM");
									data.async = false;
									data.loadXML(responseText);
								}

								const channelXML = data.getElementsByTagName("channel")[0];

								const { length } = getItemsOnBlog(channelXML.getElementsByTagName("item"));

								createItemsOnScreen(channelXML);
							}
						);
					} else {
						createItemsOnScreen(channelXML);
					}
					var swiperBlogFriday = new Swiper(".blog-friday .swiper-container", {
						pagination: false,
						simulateTouch: false,
						loop: false,
						paginationClickable: true,
						slidesPerView: 4,
						slidesPerGroup: 4,
						slidesPerColumn: 1,
						spaceBetween: 30,
						breakpoints: {
							768: {
								pagination: ".blog-friday .swiper-pagination",
								simulateTouch: true,
								slidesPerView: 1,
								slidesPerGroup: 1,
								slidesPerColumn: 1,
								loop: true
							},
						}
					});
				} else {
					blogDestinosContent.innerHTML += `<div class="col-xs-16 col-md-4 col-md-offset-4">
                            <a href="#" class="card-largetitle blog" target="_blank">
                                <div class="card-largetitle-item">
                                    <div class="card-largetitle-item--title">
                                        <img src="#" alt="">
                                    </div>
                                    <div class="card-largetitle-item--info">
                                        <div class="card-largetitle-item--info-infos">
                                            <h4>Ops!</h4>
                                            <p class="card-largetitle-item--info-text">Ocorreu um erro, tente novamente.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>`;
				}
			}
		);
	}
};
