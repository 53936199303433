/**
 * Preenche um numero com zeros ou um caractere a ser escolhido
 * @param {Number} n a ser convertido
 * @param {Number} width numero de zeros a serem adicionados
 * @param {String} z Caractere preenchedor (senao preenchido, será zero)
 */
export const pad = (n, width = 2, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const defaultPassengersData = {
	adults: 1,
	children: 0,
	infants: 0,
	cabin: "EC"
};

const flightParamsPattern = /(RT|OW).+\/(EC|EX)/;

export const LOCAL_STORAGE_NAMESPACE = "recentSearches#v2";
export const SESSION_STORAGE_NAMESPACE = "lastSearch";
export const WITHHOTEL_STORAGE_NAMESPACE = "lastSearchWH";

/**
 * Extrai os dados de uma url e transforma em um objeto
 * @param {String} urlString
 * @returns {Object}
 */
const extractSearchDataFromString = urlString => {
	if (urlString.match(flightParamsPattern) !== null) {
		const flightParams = urlString.match(flightParamsPattern)[0].split("/");
		const mountedParams = {
			tripType: flightParams[0],
			from: flightParams[1],
			to: flightParams[2],
			departure: flightParams[3]
		};

		if (flightParams.length == 9)
			return {
				...mountedParams,
				arrival: flightParams[4],
				adults: parseInt(flightParams[5]),
				children: parseInt(flightParams[6]),
				infants: parseInt(flightParams[7]),
				cabin: flightParams[8]
			};

		if (flightParams.length == 8)
			return {
				...mountedParams,
				adults: parseInt(flightParams[4]),
				children: parseInt(flightParams[5]),
				infants: parseInt(flightParams[6]),
				cabin: flightParams[7]
			};
	}
};

/**
 * Obtém última busca realizada pelo usuário
 * @param {Number} index índice do array no localStorage
 */
const getStoredSearch = (index = 0) => {
	const sessionStorageData = sessionStorage.getItem(SESSION_STORAGE_NAMESPACE);
	const localStorageData = localStorage.getItem(LOCAL_STORAGE_NAMESPACE);

	try {
		let stored;

		if (sessionStorageData) {
			stored = JSON.parse(sessionStorageData);
		} else if (localStorageData) {
			stored = JSON.parse(localStorageData);
		}

		if (stored && stored[index]) {
			return stored[index];
		}
	} catch (e) {}

	return null;
};

/**
 * Salva no sessionStorage e localStorage os dados da busca
 * @param {Object} searchData objeto da busca a ser guardado
 */
export const storeSearchData = (searchData) => {
	try {
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE, JSON.stringify([searchData]));
		sessionStorage.setItem(SESSION_STORAGE_NAMESPACE, JSON.stringify([searchData]));
	} catch (e) {
		console.log(e);
	}
};

/**
 * Monta objeto preparado para salvar no storage
 * @param {Object} airportFrom
 * @param {Object} airportTo
 * @param {Object} params
 */
export const storeSearchDataOutsideForm = (airportFrom, airportTo, params) => {
	let data = {
		adults: params.adults,
		children: params.children,
		infants: params.infants,
		cabin: params.cabin,
		departure: params.outboundDate,
		trypType: params.tripType
	};

	if (params.inboundDate) {
		data["arrival"] = params.inboundDate;
	}

	if (Array.isArray(airportFrom.data) && airportFrom.data.length > 0) {
		const airport = airportFrom.data.find(airport => {
			return airport.iata === params.from;
		});

		if (airport) {
			data["from"] = {
				city: airport.city,
				iata: airport.iata,
				name: `${airport.city} - ${airport.name} (${airport.iata})`
			};
		}
	}

	if (Array.isArray(airportTo.data) && airportTo.data.length > 0) {
		const airport = airportTo.data.find(airport => {
			return airport.iata === params.to;
		});

		if (airport) {
			data["to"] = {
				city: airport.city,
				iata: airport.iata,
				name: `${airport.city} - ${airport.name} (${airport.iata})`
			};
		}
	}

	storeSearchData(data);
};

/**
 * Devolve dados dos passageiros no
 * @param {Number} index índice do array no localStorage
 */
export const retrievePassengers = (index = 0) => {
	const urlData = extractSearchDataFromString(window.location.href);

	if (urlData) {
		return {
			adults: parseInt(urlData.adults),
			children: parseInt(urlData.children),
			infants: parseInt(urlData.infants),
			cabin: urlData.cabin
		};
	}

	const storedSearch = getStoredSearch(index);

	if (storedSearch) {
		return {
			adults: parseInt(storedSearch.adults) || 1,
			children: parseInt(storedSearch.children) || 0,
			infants: parseInt(storedSearch.infants) || 0,
			cabin: storedSearch.cabin || "EC"
		};
	}

	return defaultPassengersData;
};

/**
 * Retorna um objeto contendo dados do aeroporto selecionado num dado trecho
 * @param {String} stretch trecho a ser retornado
 * @param {Number} index índice do array no localStorage
 */
export const retrieveStretch = (stretch, index = 0) => {
	const storedSearch = getStoredSearch(index);
	return storedSearch && storedSearch[stretch] ? storedSearch[stretch] : "";
};

/**
 * Retorna a data do sessionStorage ou localStorage no formato YYYY-MM-DD
 * @param {*} dateType pode ser 'departure' ou 'arrival'
 * @param {Number} index índice do array no localStorage
 */
export const retrieveDate = (dateType, index = 0) => {
	const urlData = extractSearchDataFromString(window.location.href);

	if (urlData) {
		return urlData[dateType];
	}

	const storedSearch = getStoredSearch(index);

	return storedSearch && storedSearch[dateType] ? storedSearch[dateType] : "";
};
