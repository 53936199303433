export const PASSENGERS_LIMIT = 9;

const infantsOutnumberAdults = passengersData =>
	passengersData["infants"] > passengersData["adults"];

export const updatePassengersDataVerifiers = passengersData => {
	return {
		...passengersData,
		infantsOutnumberAdults: infantsOutnumberAdults(passengersData)
	};
};

export const getPassengersSum = passengersData =>
	passengersData.adults + passengersData.infants + passengersData.children;

export const updatePassengers = (passengersData, passengerType, _value) => {
	const value = parseInt(_value);
	let passengerTypeAmount = passengersData[passengerType];

	if (getPassengersSum(passengersData) + value - passengerTypeAmount <= PASSENGERS_LIMIT) {
		passengersData.quantityExceedsLimit = false;
	} else {
		passengersData.quantityExceedsLimit = true;
	}

	if (passengerTypeAmount == 0 && passengerType == "adults") {
		passengerTypeAmount = 1;
	}

	passengersData[passengerType] = value;
	passengersData = updatePassengersDataVerifiers(passengersData);

	return passengersData;
};

export const getPassengersResume = passengersData => {
	let passengersResume = {
		cabin: passengersData.cabin == "EC" ? "Classe econômica" : "Classe Executiva",
		amount: 0,
		label: ""
	};

	if (passengersData.infants == 0 && passengersData.children == 0) {
		return {
			...passengersResume,
			amount: passengersData.adults,
			label: passengersData.adults == 1 ? "Adulto" : "Adultos"
		};
	}

	if (passengersData.infants > 0 || passengersData.children > 0) {
		return {
			...passengersResume,
			amount: getPassengersSum(passengersData),
			label: "Passageiros"
		};
	}
};
