import moment from "moment";
import { removeActiveField } from "./utils/generalDomUtils";
import { storeSearchData } from "./utils/localStorageUtils";
import { jsDateToStrDate } from "./utils/datepickerDomUtils";
import {
	flightParamsToUrl,
	flightParamsToLocalStorage,
	$$,
	$,
	extractValues
} from "./utils/common";
import { trackBuyProcessInit, trackSearchesWithHotel } from "./utils/searchTrackers";
import { CookieTypes, getCookie, removeCookie, setCookie } from "../../shared/cookie";

const LAST_SEARCH_KEY = "@maxmilhas/last-search-intention";

export default class SearchFormWrapper {
	constructor(form, formData) {
		this.form = form;
		this.formData = formData;
		this.init();
	}

	init() {
		$$(".form-group").map(formGroup => {
			formGroup.onclick = event => event.stopPropagation();
		});

		const hideClickEvents = () => {
			removeActiveField();
			$("#passengersWrapper").setAttribute("data-show", "true");
			$$(".active-field").map(activeField => {
				activeField.className = activeField.className.replace("active-field", "");
			});
		};

		$$("body, .page-wrapper.home").map(elm => (elm.onclick = hideClickEvents));

		this.withHotelCheckbox = document.getElementById("withHotelCheckbox");

		this.form.onsubmit = this.onSubmitEvent.bind(this);

		if (this.withHotelCheckbox) {
			let searchHotelFromHome = true;
			try {
				const homeLastSearch = JSON.parse(localStorage.getItem(LAST_SEARCH_KEY));
				searchHotelFromHome = homeLastSearch.searchHotel;
			} catch (e) {}
			const hotelCheckPersisted = getCookie("search_hotel", CookieTypes.FUNCTIONAL) || "false";
			this.withHotelCheckbox.checked = searchHotelFromHome || hotelCheckPersisted === "true";
		}
	}

	onSubmitEvent(event) {
		removeActiveField();

		const isInHomePage = location.pathname === "/";
		const searchHasHotel = this.withHotelCheckbox && this.withHotelCheckbox.checked;
		let action = isInHomePage ? "Busca de Passagem na Home" : "Busca de Passagem no Detalhe da Passagem";

		try {
			trackBuyProcessInit(action);
			trackSearchesWithHotel(searchHasHotel, isInHomePage);
		} catch (e) {
			console.log("Error tracking search init on GA");
		}

		event.preventDefault();

		const formData = this.formData;
		formData.validators.stretches.validate();
		formData.validators.passengers.validate();

		const checkForErrors = $$(".form-search-group .has-error").filter(el => {
			return !el.classList.contains("hidden");
		});

		const tripType = formData.dates.arrival.getData() ? "RT" : "OW";
		const isRoundTrip = tripType === "RT";

		if (checkForErrors.length <= 0) {
			let searchObject = [
				{ trypType: tripType },
				{ from: formData.stretches.from.getData() },
				{ to: formData.stretches.to.getData() },
				{ departure: jsDateToStrDate(formData.dates.departure.getData()) },
				{ adults: formData.passengers.getData().adults },
				{ children: formData.passengers.getData().children },
				{ infants: formData.passengers.getData().infants },
				{ cabin: formData.passengers.getData().cabin }
			];

			if (isRoundTrip) {
				searchObject.splice(4, 0, {
					arrival: jsDateToStrDate(this.formData.dates.arrival.getData())
				});
			}

			const lastClick = getCookie("last_click", CookieTypes.PUBLICITY);
			const lastClickJSON = lastClick ? JSON.parse(lastClick) : undefined;

			if (lastClickJSON && lastClickJSON.utm_campaign === "alertadepreco") {
				removeCookie("last_click");
			}

			storeSearchData(extractValues(searchObject).reduce(flightParamsToLocalStorage, {}));

			const obj = {
				searchHotel: searchHasHotel,
				expanded: false,
				submitTrial: false,
				searchedOn: moment(),
			};

			localStorage.setItem(LAST_SEARCH_KEY, JSON.stringify(obj));

			if (searchHasHotel) {
				setCookie("search_hotel", "true", CookieTypes.FUNCTIONAL);

				const departureDate = moment(formData.dates.departure.getData()).format('YYYY-MM-DD');
				const arrivalDate = isRoundTrip ? moment(formData.dates.arrival.getData()).format('YYYY-MM-DD') : undefined;
				const isAllToAirports = formData.stretches.to.getData().name.includes('Todos');
				const toCity = encodeURI(formData.stretches.to.getData().city);
				const toIata = formData.stretches.to.getData().iata;
				const adults = formData.passengers.getData().adults;
				const children = formData.passengers.getData().children;
				const infants = formData.passengers.getData().infants;
				const allKids = children + infants;

				const getChildrenInfantAges = (passengerTypeQtd, urlAge) => {
					if (passengerTypeQtd <= 0) { return }
					let agesStr = [];
					for (let i = 0; i < passengerTypeQtd; i++) {
						agesStr.push(urlAge)
					}
					return agesStr.join(";");
				}

				const childrenAges = getChildrenInfantAges(children, "age=3");
				const infantsAges = getChildrenInfantAges(infants, "age=0");
				const oldHotelUrl = 'https://sp.booking.com/searchresults.pt-br.html?aid=1771436'
				let newHotelUrl = isAllToAirports 
					? `https://hotel.maxmilhas.com.br/search.do?locale=pt_BR&POS=maxmilhas_BR&q-destination=${toCity}`
					: `https://widgets.hotels.com/airport_search.do?widgetTarget=XSellWidgetPlace&airportArrival=${toIata}&widgetID=122`
				const isHotelAbActived = window.headerHotelLink;

				window.open(`${"/busca-passagens-aereas" + extractValues(searchObject).reduce(flightParamsToUrl, "")}`, "_blank");
				if (!isHotelAbActived) {
					window.location = `${oldHotelUrl};checkin=${departureDate};${arrivalDate ? `checkout=${arrivalDate};` : ""}iata_orr=1;iata=${toIata};group_adults=${adults};group_children=${allKids};${childrenAges ? `${childrenAges};` : ""}${infantsAges ? `${infantsAges};` : ""}label=SE-Maxmilhas`;
				} else {
					window.location = `${newHotelUrl}&q-check-in=${departureDate}&q-check-out=${arrivalDate ? arrivalDate : moment(departureDate).add(1, 'days').format('YYYY-MM-DD')}&q-rooms=1&q-room-0-adults=${adults}&wapb3=|c.605212|l.pt_BR|t.site|s.search_omnibox&utm_source=maxmilhas&utm_medium=site_search_omnibox`;
				}
			} else {
				setCookie("search_hotel", "false", CookieTypes.FUNCTIONAL);
				window.location.href =
					"/busca-passagens-aereas" + extractValues(searchObject).reduce(flightParamsToUrl, "");
			}
		}
	}
}
