const ACTIVE_FIELD_CLASS = "active-field";
const FORM_GROUP_CLASS = "form-group";
const BODY_LOCK_CLASS = "body-lock";

import { $$ } from "./common";
/**
 * mostra o campo relativo a um elemento contendo um ${ACTIVE_FIELD_CLASS}
 */
export const showField = function(elm, parentElm) {
	removeActiveField();
	const body = document.querySelector("body");
	//body.className = body.className + ` ${BODY_LOCK_CLASS}`;

	const parent = parentElm || (elm.parentElement || this.parentElement);
	const input = parent.querySelector("input");

	if (parent.className.match(ACTIVE_FIELD_CLASS) == null) {
		parent.className = parent.className + ` ${ACTIVE_FIELD_CLASS}`;
		if (input !== null) {
			input.focus();
			input.select();
		}
	} else {
		parent.className = FORM_GROUP_CLASS;
	}
};

/**
 * Remove a classe 'active-field' do campo selecionado,
 * deixando a tela sem nenhum input da busca visível na tela
 * @param {Object} event
 */
export const removeActiveField = () => {
	const body = document.querySelector("body");
	body.className = body.className.replace(/\sbody-lock/, "");

	$$(`.${ACTIVE_FIELD_CLASS}`).map(elm => {
		elm.className = (elm.className || "").replace(/\sactive-field/, "");
	});
};
