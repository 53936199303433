import { PASSENGERS_LIMIT } from "../utils/passengersUpdaterUtils";

export default class PassengerValidators {
	constructor(passengersWrapper) {
		this.passengersWrapper = passengersWrapper;
		this.errorsCount = 0;
		this.errorsData = {};
		this.validate = () => {
			this.errorsData = {};
			const passengersData = this.passengersWrapper.passengersData;

			if (passengersData.babiesOutnumberAdults) {
				this.errorsData = {
					...this.errorsData,
					babiesOutnumberAdultsMessage: "O número de bebês deve ser inferior ao de adultos"
				};
			}

			if (passengersData.quantityExceedsLimit) {
				this.errorsData = {
					...this.errorsData,
					quantityExceedsLimitMessage: "O número maximo de passageiros é " + PASSENGERS_LIMIT
				};
			}

			return this.errorsData;
		};
	}
}
