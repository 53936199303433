import { defaultDiacriticsRemovalMap } from "./defaultDiacriticsRemovalMap";

/**
 * Retorna um array contendo os índices do termo buscado
 * em uma string
 *
 * @param {String} term Termo da busca
 * @param {String} subject texto a ser pesquisado
 * @param {Boolean} caseSensitive faz uma pesquisa em um subject/term case sensitive
 */
const getIndicesOf = (term, subject, caseSensitive) => {
	let termLen = term.length;
	if (termLen == 0) {
		return [];
	}
	let startIndex = 0,
		index,
		indices = [];
	if (!caseSensitive) {
		subject = subject.toLowerCase();
		term = term.toLowerCase();
	}
	while ((index = subject.indexOf(term, startIndex)) > -1) {
		indices.push(index);
		startIndex = index + termLen;
	}
	return indices;
};

/**
 * Retorna o caractere sem acento ou o próprio caractere, caso não seja um caractere acentuado
 * @param {String} char caractere a ser substituído
 */
const getLetterFromDiacriticsMap = char => {
	let diacriticsMap = {};

	for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
		let letters = defaultDiacriticsRemovalMap[i].letters;
		for (let j = 0; j < letters.length; j++) {
			diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap[i].base;
		}
	}

	return diacriticsMap[char];
};

/**
 * Remove acentos de uma string (á => a)
 * @param {String} str
 * @returns {String} uma string sem acentos
 */
const removeDiacritics = str =>
	str.replace(/[^\u0000-\u007E]/g, function(char) {
		return getLetterFromDiacriticsMap(char) || char;
	});

export const camelcase = str => {
	return str
		.toLowerCase()
		.split(" ")
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(" ");
};

/**
 * Decora um texto, evidenciando o texto buscado, envolvendo-o em uma tag html
 * @param {String} searchTerm termo a ser buscado
 * @param {String} subject texto base da busca
 * @returns {String}
 */
export const decorateSearch = function(searchTerm, subject) {
	const indices = getIndicesOf(removeDiacritics(searchTerm), removeDiacritics(subject));

	return subject
		.split("")
		.map((letter, index) => {
			const endingIndices = indices.map(index => index + searchTerm.length - 1);
			if (indices.indexOf(index) > -1) {
				letter = "<span class='match'>" + letter;
			}

			if (endingIndices.indexOf(index) > -1) {
				letter = letter + "</span>";
			}
			return letter;
		})
		.join("");
};
