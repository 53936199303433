import { decorateWithError, removeErrorDecoration } from "../utils/common";
import { checkStretches } from "../utils/autocompleteDomUtils";

export default class AutoCompleteValidators {
	constructor(outboundData, inboundData) {
		this.outboundData = outboundData;
		this.inboundData = inboundData;

		inboundData.elm.addEventListener("blur", this.inputValidation.bind(this, this.inboundData));
		outboundData.elm.addEventListener("blur", this.inputValidation.bind(this, this.outboundData));

		outboundData.elm.addEventListener("click", e => {
			e.target.value = "";
		});
		inboundData.elm.addEventListener("click", e => {
			e.target.value = "";
		});

		this.validate = () => {
			this.hasEmptyStretch();
			this.stretchesHasSameCities();
		};
	}

	inputValidation(autoCompleteInstance, event) {
		this.hasEmptyStretch(event.target.id);
		this.inputValueDiffersFromAirportData(event.target, autoCompleteInstance);
		if (this.outboundData.airportData && this.inboundData.airportData) {
			checkStretches(this.outboundData, this.inboundData);
		}
	}

	inputValueDiffersFromAirportData(element, autoCompleteInstance) {
		if (autoCompleteInstance.getData() && element.value !== autoCompleteInstance.getData().name) {
			element.value = autoCompleteInstance.getData().name;
		}
	}

	stretchesHasSameCities(elementId, targetId) {
		if (elementId === targetId) {
			checkStretches(this.outboundData, this.inboundData);
		}
	}

	hasEmptyStretch(targetId) {
		const inbound = this.inboundData.elm.parentElement;
		const outbound = this.outboundData.elm.parentElement;

		removeErrorDecoration(inbound);
		removeErrorDecoration(outbound);

		try {
			if (targetId) {
				if (targetId === "search_form_from" && this.outboundData.airportData == undefined) {
					decorateWithError(outbound, "Escolha a origem da sua viagem");
				}
				if (targetId === "search_form_to" && this.inboundData.airportData == undefined) {
					decorateWithError(inbound, "Escolha o destino da sua viagem");
				}
			} else {
				if (this.inboundData.airportData == undefined) {
					decorateWithError(inbound, "Escolha o destino da sua viagem");
				}

				if (this.outboundData.airportData == undefined) {
					decorateWithError(outbound, "Escolha a origem da sua viagem");
				}
			}
		} catch (e) {
			console.log("erro verificando trechos vazios: ", e);
			this.errorsCount++;
		}
	}
}
