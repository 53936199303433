export const bindUpdatePassengers = (select, passengersWrapperInstance) => {
	const value = select.name;
	select.value = passengersWrapperInstance.passengersData[value];

	select.onchange = function(event) {
		const passengerType = this.name;
		const value = this.value;
		passengersWrapperInstance.updatePassengersData(passengerType, value);
		passengersWrapperInstance.updateView();
	};
};
