import AutoCompleteWrapper from "./components/searchform/AutoCompleteWrapper";
import DatePickerWrapper from "./components/searchform/DatePickerWrapper";
import PassengersWrapper from "./components/searchform/PassengersWrapper";
import SearchFormWrapper from "./components/searchform/SearchFormWrapper";
import SearchFormResumeWrapper from "./components/searchform/SearchResumeWrapper";

import AutoCompleteValidators from "./components/searchform/component-validators/AutoCompleteValidators";
import DateValidators from "./components/searchform/component-validators/DateValidators";
import PassengerValidators from "./components/searchform/component-validators/PassengerValidators";

import { $, getNearbyLocation } from "./shared/utils";

const btnChangeFlight = $("#change_flight_direction");
const stretchFrom = new AutoCompleteWrapper($("#search_form_from"), "from");
const stretchTo = new AutoCompleteWrapper($("#search_form_to"), "to");
const autoCompleteValidators = new AutoCompleteValidators(stretchFrom, stretchTo);
const passengersInfo = new PassengersWrapper($(".dropdown .fake-input.form-control"));
const passengerValidators = new PassengerValidators(passengersInfo);
const inboundDateInfo = new DatePickerWrapper($("#search_form_inbound_date"), "arrival");
const outboundDateInfo = new DatePickerWrapper(
	$("#search_form_outbound_date"),
	"departure",
	inboundDateInfo.calendarInstance
);

new SearchFormResumeWrapper(stretchFrom, stretchTo);

new DateValidators(inboundDateInfo, outboundDateInfo);

new SearchFormWrapper($(".form-search-group"), {
	stretches: {
		from: stretchFrom,
		to: stretchTo
	},
	dates: {
		departure: outboundDateInfo,
		arrival: inboundDateInfo
	},
	passengers: passengersInfo,
	validators: {
		stretches: autoCompleteValidators,
		passengers: passengerValidators
	}
});

if (btnChangeFlight) {
	btnChangeFlight.addEventListener("click", e => {
		const dataFrom = stretchFrom.getData();
		const dataTo = stretchTo.getData();

		if (dataFrom || dataTo) {
			stretchFrom.setData(dataTo);
			stretchTo.setData(dataFrom);

			stretchFrom.updateValue();
			stretchTo.updateValue();

			$("#change_flight_direction").classList.toggle("active");
		}

		e.preventDefault();
	});
}

if (
	document.body.classList.length === 1 &&
	document.body.classList[0] === "home-page" &&
	localStorage.getItem("recentSearches#v2") === null
) {
	getNearbyLocation().then(
		airports => {
			if (Array.isArray(airports) && airports.length > 0) {
				const fromGeoLocation = true;
				const airport = airports[0];

				let name, iata;

				if (airport.group !== undefined) {
					name = "Todos os aeroportos";
					iata = airport.group;
				} else {
					name = airport.city;
					iata = airport.iata;
				}

				const airportDataDecorated = {
					name: name,
					city: airport.city,
					iata: iata,
					country: airport.country
				};

				const airportData = {
					city: airport.city,
					iata: iata,
					name: `${airport.city} - ${name} (${iata})`
				};

				stretchFrom.decorateField(
					document.querySelector(`#${stretchFrom.elm.id}`),
					airportDataDecorated,
					false,
					fromGeoLocation
				);
				stretchFrom.setData(airportData);
			}
		},
		error => {
			console.error(error);
		}
	);
}

export { stretchFrom, stretchTo };
