import { retrieveStretch } from "./utils/localStorageUtils";
import { setDataFromStorage } from "./utils/autocompleteDomUtils";
import PubSub from "pubsub-js";
import "./../../shared/pubsub-triggers";

export default class SearchFormResumeWrapper {
	constructor(stretchFrom, stretchTo) {
		const resumeBar = document.getElementById("trip_resume");
		const headerFixed = document.getElementById("header-fixed");
		const newHeader = document.querySelector('.newheader');
		const searchFormWrapper = document.getElementById("search-form-section");
		const iconEdit = document.getElementById("icon_edit");
		const editText = document.getElementById("edit_text");

		if (resumeBar !== null) {
			const resumeBarHooks = () => {
				if (searchFormWrapper.classList.contains("hidden")) {
					const airportDataFromLocalStorage = retrieveStretch("from");
					const airportDataToLocalStorage = retrieveStretch("to");

					if (airportDataFromLocalStorage && airportDataToLocalStorage) {
						setDataFromStorage(stretchFrom, airportDataFromLocalStorage);
						setDataFromStorage(stretchTo, airportDataToLocalStorage);
					}

					searchFormWrapper.classList.remove("hidden");
					iconEdit.className = "icon-close";
					editText.innerText = "OCULTAR BUSCA";
					headerFixed.classList.add("active");
					newHeader.classList.add("active");

					PubSub.publish("dataLayer", {
						event: "gaEvent",
						gaEventCategory: "Resultados de busca",
						gaEventAction: "Menu topo",
						gaEventLabel: "Alterar busca"
					});
				} else {
					headerFixed.classList.remove("active");
					newHeader.classList.remove("active");
					searchFormWrapper.classList.add("hidden");
					iconEdit.className = "icon-pencil";
					editText.innerText = "ALTERAR BUSCA";
				}
			};

			resumeBar.addEventListener("click", resumeBarHooks);
		}
	}
}
